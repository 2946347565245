var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bunk-details"},[_c('div',{staticClass:"details-header"},[(_vm.selectedBunkIds.length <= 1)?_c('div',{staticClass:"bunk-details-info-header"},[_c('BunkIcon',{attrs:{"size":"24","iconColor":"var(--color-pop-01)"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.bunk?.cabinNr)+" "+_vm._s(_vm.bunk?.bunkNr))]),_c('BunkStatusPill',{attrs:{"disabledTime":_vm.updatedBunk.disabledDateTime,"bunkStatus":_vm.bunkStatus}})],1):_c('div',[_c('div',{staticClass:"title"},[_vm._v("SELECTED BUNKS SUMMARY ("+_vm._s(_vm.selectedBunkIds.length)+")")])]),_c('div',{staticClass:"close-icon",on:{"click":function($event){return _vm.$emit('close')}}},[_c('close-icon',{attrs:{"size":"24","iconColor":"var(--color-brand-01)"}})],1)]),_c('div',{staticClass:"bunk-details-info-container"},[(_vm.selectedBunkIds.length > 1)?_c('div',{staticClass:"bunk-chart-info"},[_c('chart-donut',{attrs:{"initialValues":_vm.countValues}}),_c('ul',{staticClass:"info-list info-bunks"},_vm._l((_vm.countValues),function(bunkStatus){return _c('li',{key:'bunkStatus-' + bunkStatus.name},[_c('div',{staticClass:"category-circle",style:({ background: bunkStatus.color })}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: bunkStatus.name,
              delay: {
                show: 500,
              },
            }),expression:"{\n              content: bunkStatus.name,\n              delay: {\n                show: 500,\n              },\n            }"}],staticClass:"label"},[_vm._v(" "+_vm._s(bunkStatus.name)+" ")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(bunkStatus.count))])])}),0)],1):_vm._e(),_c('div',{staticClass:"bunk-details-form"},[_c('form',{attrs:{"action":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('SInput',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.bunk
              ? `${_vm.bunk.cabinNr} ${_vm.bunk.bunkNr}`
              : Array.isArray(_vm.bunks)
                ? _vm.bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')
                : '',
            placement: 'bottom',
          }),expression:"{\n            content: bunk\n              ? `${bunk.cabinNr} ${bunk.bunkNr}`\n              : Array.isArray(bunks)\n                ? bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')\n                : '',\n            placement: 'bottom',\n          }"}],attrs:{"value":_vm.bunk
              ? `${_vm.bunk.cabinNr} ${_vm.bunk.bunkNr}`
              : Array.isArray(_vm.bunks)
                ? _vm.bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')
                : '',"disabled":true,"label":"BUNK"}}),(_vm.selectedBunkIds.length <= 1 && _vm.bunk)?_c('div',{staticClass:"bunk-booking-info"},[_c('div',{staticClass:"reservations-container"},[_c('SLabel',{attrs:{"for":"currentResidents"}},[_vm._v("Current Reservation")]),_vm._l((_vm.bunk.currentResidents),function(reservation){return _c('BunkReservationItem',{key:reservation.cabinBunkId + Math.random(),attrs:{"bunkReservation":reservation,"isCurrentReservation":true}})})],2),_c('div',{staticClass:"reservations-container"},[_c('SLabel',[_vm._v("Future Reservations")]),_vm._l((_vm.bunk.plannedReservations),function(reservation){return _c('BunkReservationItem',{key:reservation.cabinBunkId + Math.random(),attrs:{"bunkReservation":reservation}})})],2)]):_vm._e(),_c('div',{staticClass:"muster-station-selection-container"},[_vm._l((_vm.musterTypes),function(musterType){return _c('div',{key:musterType.id},[_c('SLabel',{attrs:{"for":musterType.id}},[_vm._v("Muster station - "+_vm._s(musterType.label))]),_c('div',{staticClass:"muster-station-selectors"},[_c('VSelect',{attrs:{"id":musterType.id + '-primary',"deselectFromDropdown":"","clearable":true,"append-to-body":"","calculatePosition":_vm.VSelectPopperPositioning,"reduce":(option) => option.id,"getOptionLabel":(option) => option.label,"placeholder":_vm.updatedMusterStationsPerMusterTypes.find(
                    (muster) => muster.musterTypeId === musterType.id,
                  )?.isMixed
                    ? 'Mixed'
                    : 'Select the primary muster station',"options":_vm.musterStations,"value":_vm.updatedMusterStationsPerMusterTypes.find(
                    (muster) => muster.musterTypeId === musterType.id,
                  )?.primaryMusterStationId},on:{"input":(primaryMusterStationId) =>
                    _vm.handleMusterStationUpdate(musterType.id, primaryMusterStationId, true)},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected-option"},[_c('p',{staticClass:"selected-option-prefix"},[_vm._v("1.")]),_c('div',{staticClass:"selected-option-label"},[_vm._v(_vm._s(option.label))])])]}},{key:"option",fn:function(musterStation){return [_c('div',{staticClass:"muster-station-option"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"main"},[_vm._v(_vm._s(musterStation.label))]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.nodes .filter((n) => n.musterStation?.id === musterStation.id) .map((n) => n.mac) .join(", "))+" ")])]),_c('div',{staticClass:"right"})])]}},{key:"no-options",fn:function({ search }){return [(search)?_c('p',[_vm._v("No matching muster stations found")]):_c('p',[_vm._v("No muster stations to choose from")])]}}],null,true)}),_c('VSelect',{attrs:{"id":musterType.id + '-secondary',"deselectFromDropdown":"","clearable":true,"append-to-body":"","calculatePosition":_vm.VSelectPopperPositioning,"reduce":(option) => option.id,"getOptionLabel":(option) => option.label,"placeholder":_vm.updatedMusterStationsPerMusterTypes.find(
                    (muster) => muster.musterTypeId === musterType.id,
                  )?.isMixed
                    ? 'Mixed'
                    : 'Select the secondary muster station, if desirable',"options":_vm.musterStations,"value":_vm.updatedMusterStationsPerMusterTypes.find(
                    (muster) => muster.musterTypeId === musterType.id,
                  )?.secondaryMusterStationId},on:{"input":(secondaryMusterStationId) =>
                    _vm.handleMusterStationUpdate(musterType.id, secondaryMusterStationId, false)},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected-option"},[_c('p',{staticClass:"selected-option-prefix"},[_vm._v("2.")]),_c('div',{staticClass:"selected-option-label"},[_vm._v(_vm._s(option.label))])])]}},{key:"option",fn:function(musterStation){return [_c('div',{staticClass:"muster-station-option"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"main"},[_vm._v(_vm._s(musterStation.label))]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.nodes .filter((n) => n.musterStation?.id === musterStation.id) .map((n) => n.mac) .join(", "))+" ")])]),_c('div',{staticClass:"right"})])]}},{key:"no-options",fn:function({ search }){return [(search)?_c('p',[_vm._v("No matching muster stations found")]):_c('p',[_vm._v("No muster stations to choose from")])]}}],null,true)})],1)],1)}),(
              (_vm.selectedBunkIds.length <= 1
                ? (_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 1) ||
                  !_vm.updatedBunk.disabledDateTime
                : _vm.bunks.every(
                    (b) =>
                      _vm.selectedBunkIds.includes(b.id) &&
                      _vm.bunk?.currentResidents &&
                      b.currentResidents &&
                      b.currentResidents.length > 1,
                  )) && _vm.isMusterStationPerMusterTypeChanged
            )?_c('InfoBox',{attrs:{"iconColor":"var(--color-status-alert)"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(" When updating muster stations it will update the muster stations of all current residents from the selected bunks. Make sure this is the desired action. If not, we recommend that you unassign the resident(s) from the bunk(s) before updating the muster stations. ")])]},proxy:true}],null,false,852420299)}):_vm._e()],2),_c('div',{staticClass:"form-buttons-container"},[_c('NormalButton',{attrs:{"type":"button","icon":"","tertiary":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('CancelIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-01)"}}),_vm._v(" Cancel ")],1),_c('NormalButton',{attrs:{"type":"submit","icon":"","primary":"","disabled":!_vm.isChanged}},[_c('SaveIcon',{attrs:{"size":"16","iconColor":_vm.isChanged ? 'var(--color-pop-01)' : 'var(--color-brand-03)'}}),_vm._v(" Save ")],1)],1)],1)]),_c('section',{staticClass:"button-section"},[_c('div',{staticClass:"top-buttons"},[_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus !== _vm.BunkStatus.DISABLED : false) ||
              _vm.bunks.every((b) => b.disabledDateTime === null)
                ? `<center style='width: fit-content;'>${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is enabled<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              (selectedBunkIds.length === 1 ? bunkStatus !== BunkStatus.DISABLED : false) ||\n              bunks.every((b) => b.disabledDateTime === null)\n                ? `<center style='width: fit-content;'>${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is enabled<center>`\n                : '',\n            html: true,\n          }"}],attrs:{"primary":"","disabled":(_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus !== _vm.BunkStatus.DISABLED : false) ||
            _vm.bunks.every((b) => b.disabledDateTime === null)},nativeOn:{"click":function($event){return _vm.handleChangeOfBunkActiveState(true)}}},[_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `ENABLE ${_vm.selectedBunkIds.length} BUNKS` : "ENABLE BUNK")+" ")]),_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              _vm.bunks.every((b) => b.disabledDateTime) ||
              _vm.bunk?.currentResidents ||
              _vm.bunks.some((b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents) ||
              (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus === _vm.BunkStatus.DISABLED : false)
                ? `<center style='width: 300px'>Not possible to disable ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is occupied by a resident<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              bunks.every((b) => b.disabledDateTime) ||\n              bunk?.currentResidents ||\n              bunks.some((b) => selectedBunkIds.includes(b.id) && b.currentResidents) ||\n              (selectedBunkIds.length === 1 ? bunkStatus === BunkStatus.DISABLED : false)\n                ? `<center style='width: 300px'>Not possible to disable ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is occupied by a resident<center>`\n                : '',\n            html: true,\n          }"}],attrs:{"secondary":"","disabled":_vm.bunks.every((b) => b.disabledDateTime) ||
            _vm.bunk?.currentResidents ||
            _vm.bunks.some((b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents) ||
            (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus === _vm.BunkStatus.DISABLED : false)},nativeOn:{"click":function($event){return _vm.handleChangeOfBunkActiveState(false)}}},[_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `DISABLE ${_vm.selectedBunkIds.length} BUNKS` : "DISABLE BUNK")+" ")])],1),_c('div',{staticClass:"bottom-buttons",class:_vm.selectedBunkIds.length > 1 ? 'flex-column' : ''},[_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              _vm.bunk?.currentResidents ||
              _vm.bunks.some((b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents)
                ? `<center style='width: 300px'>Not possible to delete ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is occupied by a resident<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              bunk?.currentResidents ||\n              bunks.some((b) => selectedBunkIds.includes(b.id) && b.currentResidents)\n                ? `<center style='width: 300px'>Not possible to delete ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is occupied by a resident<center>`\n                : '',\n            html: true,\n          }"}],staticStyle:{"max-width":"fit-content"},attrs:{"icon":"","disabled":_vm.bunk?.currentResidents ||
            _vm.bunks.some((b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents),"tertiary":""},nativeOn:{"click":function($event){return _vm.$emit('deleteBunk')}}},[_c('ThrashBinIcon',{attrs:{"size":"16px","iconColor":_vm.bunk?.currentResidents ||
              _vm.bunks.some((b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents)
                ? 'var(--color-brand-03)'
                : 'var(--color-brand-01)'}}),_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `DELETE ${_vm.selectedBunkIds.length} BUNKS` : "DELETE BUNK")+" ")],1),_c('BunksExporter',{attrs:{"bunks":_vm.bunks,"selectedBunkIds":_vm.selectedBunkIds}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }