import { environment } from "../../environments/environment";
import {
  DashboardNode,
  ApiBaseLayer,
  ApiView,
  CustomFetchOptions,
  SensorDataObject,
  Snapshot,
  SystemStatus,
  SensorData,
} from "@/typedef";
import customFetch from "@/helpers/customFetch";
import { ApiNode } from "scanreach-frontend-components/src/types/ApiNode.type";

const apiAddress = environment.apiAddress;

let fetchSnapshotLock = false;
let fetchNodeSensorDataLock = false;
let fetchNodeDetailsLock = false;
/**
 * Save all changes made to a specific node
 * @param {DashboardNode} inpNode
 * @return {Promise<>}
 */
export function saveNode(inpNode: DashboardNode) {
  // Remove fields we don't need:
  const node = {
    mac: inpNode.mac,
    label: inpNode.label,
    x: inpNode.x !== null ? Math.round(inpNode.x) : null,
    y: inpNode.y !== null ? Math.round(inpNode.y) : null,
    musterStation: inpNode.musterStation,
    musterBox: inpNode.musterBox,
    isAlarmNode: inpNode.isAlarmNode,
  };

  // Code below to be used on new api
  return customFetch(apiAddress + "/nodes/" + inpNode.id, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(node),
  });
}

/**
 * @return {Promise<Array<ApiBaseLayer>>} A promise with roles as an array of strings
 */
export function fetchBaseLayers({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiBaseLayer[]> {
  return new Promise((resolve, reject) => {
    try {
      customFetch(
        apiAddress + "/baselayers",
        {
          method: "GET",
          credentials: "include",
        },
        showWarning,
      )
        .then((res) => res.json())
        .then((baseLayers: ApiBaseLayer[]) => {
          resolve(baseLayers);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}
/**
 * @return {Promise<ApiView>} A promise with roles as an array of strings
 */
export function fetchView({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiView> {
  return new Promise((resolve, reject) => {
    try {
      customFetch(
        apiAddress + "/views",
        {
          method: "GET",
          credentials: "include",
        },
        showWarning,
      )
        .then((res) => res.json())
        .then((views: ApiView[]) => {
          resolve(views[0]);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

// ============== REST-api for config management =================

/**
 * Get all nodes
 * Might implement querying in the future.
 * @returns {Promise<ApiNodes[]>}
 */
export function fetchNodes({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiNode[]> {
  return customFetch(
    `${apiAddress}/nodes`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  ).then((response) => {
    return response.json();
  });
}

export function fetchNode(nodeId: number): Promise<ApiNode> {
  return customFetch(`${apiAddress}/nodes/${nodeId}`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

// =================== SensorData ========================
/**
 * Get snapshot
 */
export function fetchSnapshot({ showWarning = false }: CustomFetchOptions = {}): Promise<Snapshot> {
  return new Promise((resolve, reject) => {
    if (!fetchSnapshotLock) {
      fetchSnapshotLock = true;
      customFetch(
        `${apiAddress}/sensordata/snapshot`,
        {
          method: "GET",
          credentials: "include",
        },
        showWarning,
      )
        .then((response) => {
          return response.json();
        })
        .then((snapshot) => {
          fetchSnapshotLock = false;
          resolve(snapshot);
        })
        .catch((error) => {
          fetchSnapshotLock = false;
          reject(error);
        });
    } else {
      reject(new Error("Already waiting for this snapshot."));
    }
  });
}

/**
 * Get sensorData for list of nodeMacs
 */
export async function fetchNodeSensorData(nodeMacs?: string[]): Promise<SensorDataObject> {
  if (fetchNodeSensorDataLock) {
    throw new Error("Already waiting for this snapshot.");
  }

  fetchNodeSensorDataLock = true;

  try {
    const response = await customFetch(
      `${apiAddress}/sensordata/node`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: nodeMacs ? JSON.stringify(nodeMacs) : "[]",
      },
      false,
      true,
    );

    return await response.json();
  } finally {
    fetchNodeSensorDataLock = false;
  }
}

/**
 * Get nodeDetails for a nodeMac
 */
export async function fetchNodeDetails(nodeMac: string): Promise<SensorData> {
  if (fetchNodeDetailsLock) {
    throw new Error("Already waiting for this node details.");
  }

  try {
    fetchNodeDetailsLock = true;
    const response = await customFetch(`${apiAddress}/sensordata/node/${nodeMac}`, {
      method: "GET",
      credentials: "include",
    });

    if (response.ok) {
      return (await response.json()) as SensorData;
    }
    throw new Error("Failed to fetch node details: " + response.statusText);
  } finally {
    fetchNodeDetailsLock = false;
  }
}

/**
 * Get status of system
 */
export function fetchSystemStatus(): Promise<SystemStatus> {
  return customFetch(`${apiAddress}/status`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

/**
 * Get last alarmSilenceTime
 */
export function fetchAlarmSilenceTime(): Promise<Date> {
  return customFetch(`${apiAddress}/AlarmSilence`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

/**
 * Set alarmSilenceTime
 */
export function putAlarmSilenceTime(): Promise<Response> {
  return customFetch(`${apiAddress}/AlarmSilence`, {
    method: "PUT",
    credentials: "include",
  });
}
