import customFetch from "../helpers/customFetch";
import { onMounted, ref } from "vue";
import signalRSocketHandler from "../helpers/signalRSocketHandler";
import { environment } from "../../environments/environment";
import { Guid } from "../typedef";
import { ApiMusterStation } from "scanreach-frontend-components/src/types/ApiNode.type";
import { ApiPerson } from "../types/personTypes";
import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";
import { useStore } from "@/store/desktopStore";
import {
  headersAndRowsToCsv,
  headersAndRowsToSpreadsheet,
} from "scanreach-frontend-components/src/utils/csvAndSpreadsheetUtils";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";
import { get } from "lodash";
import useMusterTypes, { MusterType } from "./useMusterTypes";
import { sortList } from "@/helpers/sortUtils";
import { SortDir } from "./useSort";
import { formatDate } from "scanreach-frontend-components/src/utils/timeUtils";

const bunks = ref<CabinBunk[] | null>(null);
const isLoading = ref(false);

const initialLoaded = ref(false);
const apiBaseUrl = environment.apiAddress; // TODO: Change to genericApiAddress when the backend is updated

export default function () {
  const { pushToast } = useToaster();
  const store = useStore();
  const { musterTypes } = useMusterTypes();
  onMounted(() => {
    if (!initialLoaded.value) {
      initialLoaded.value = true;

      if (process.env.NODE_ENV != "test") {
        subscribeToReconnectAndBroadSignalREvents();
      }
    }
  });

  async function fetchAndStoreBunks(): Promise<CabinBunk[]> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiBaseUrl}/cabinBunks`, {
        method: "GET",
      });

      if (resp.ok) {
        const data = (await resp.json()) as CabinBunk[];

        while (!store.state.siteConfigLoaded) {
          // Personnel has not been loaded yet, wait for it to be loaded
          await new Promise((resolve) => setTimeout(resolve, 50));
        }

        data.forEach((bunk) => {
          // For each person in the bunk, fetch the person if it is not already available
          bunk.cabinBunkHistory?.forEach((history) => {
            if (!history.person) {
              // Fetch person
              history.person = store.getters.getPersonById(history.personId);
            }
          });
          bunk.currentResidents = getCurrentResidents(bunk);
          bunk.plannedReservations = getPlannedReservations(bunk);
        });
        bunks.value = data;
        return bunks.value;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  function subscribeToBunkConfigSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveCabinBunkChangeEvents", (cabinBunks: CabinBunk[]) => {
      if (!bunks.value) {
        return;
      }

      for (const updatedBunk of cabinBunks) {
        const existingBunkIndex = bunks.value?.findIndex((b) => b.id === updatedBunk.id);

        if (updatedBunk.deletedUtcDateTime) {
          if (existingBunkIndex !== undefined && existingBunkIndex >= 0) {
            bunks.value?.splice(existingBunkIndex, 1);
          }
          return;
        }

        if (existingBunkIndex !== undefined && existingBunkIndex >= 0) {
          // Updating each property manually to ensure bunkDetails panel is updated when changes are made
          const existingBunk = bunks.value[existingBunkIndex];
          existingBunk.bunkNr = updatedBunk.bunkNr;
          existingBunk.cabinNr = updatedBunk.cabinNr;
          existingBunk.disabledDateTime = updatedBunk.disabledDateTime;
          existingBunk.comment = updatedBunk.comment;
          existingBunk.defaultMusterStationPerMusterType = updatedBunk.defaultMusterStationPerMusterType;
          existingBunk.lastUpdatedAt = updatedBunk.lastUpdatedAt;

          // For each person in the bunk, fetch the person if it is not already available
          updatedBunk.cabinBunkHistory?.forEach((history) => {
            if (!history.person) {
              // Fetch person
              history.person = store.getters.getPersonById(history.personId);
            }
          });
          existingBunk.cabinBunkHistory = updatedBunk.cabinBunkHistory;
          existingBunk.currentResidents = getCurrentResidents(updatedBunk);
          existingBunk.plannedReservations = getPlannedReservations(updatedBunk);
        } else {
          bunks.value?.push(updatedBunk);
        }
      }
    });

    signalRSocketHandler.on("ReceiveCabinBunkEntryChangeEvents", (cabinBunkEntries: CabinBunkEntry[]) => {
      if (!bunks.value) {
        return;
      }

      for (const cabinBunkEntry of cabinBunkEntries) {
        const existingBunk = bunks.value?.find((b) => b.id === cabinBunkEntry.cabinBunkId);

        if (!existingBunk) {
          // Unable to find cabinBunk, data from backend has probably not loaded yet
          return;
        }

        const existingCabinBunkEntryIndex = existingBunk.cabinBunkHistory?.findIndex(
          (h) => h.id === cabinBunkEntry.id,
        );

        if (cabinBunkEntry.deletedUtcDateTime) {
          if (existingCabinBunkEntryIndex !== undefined && existingCabinBunkEntryIndex >= 0) {
            existingBunk.cabinBunkHistory?.splice(existingCabinBunkEntryIndex, 1);
            existingBunk.currentResidents = getCurrentResidents(existingBunk);
            existingBunk.plannedReservations = getPlannedReservations(existingBunk);
          }
          return;
        }

        if (!cabinBunkEntry.person) {
          // Fetch person
          // TODO: Consider including person in data from backend
          cabinBunkEntry.person = store.getters.getPersonById(cabinBunkEntry.personId);
        }

        if (existingCabinBunkEntryIndex !== undefined && existingCabinBunkEntryIndex >= 0) {
          const ex = existingBunk.cabinBunkHistory[existingCabinBunkEntryIndex];

          // Manually updating each property to ensure bunkDetails panel is updated when changes are made
          ex.checkIn = cabinBunkEntry.checkIn;
          ex.checkOut = cabinBunkEntry.checkOut;
          ex.plannedCheckIn = cabinBunkEntry.plannedCheckIn;
          ex.plannedCheckOut = cabinBunkEntry.plannedCheckOut;
          ex.comment = cabinBunkEntry.comment;
          ex.lastUpdatedAt = cabinBunkEntry.lastUpdatedAt;
        } else {
          existingBunk.cabinBunkHistory?.push(cabinBunkEntry);
        }

        existingBunk.currentResidents = getCurrentResidents(existingBunk);
        existingBunk.plannedReservations = getPlannedReservations(existingBunk);
      }
    });
  }

  function subscribeToReconnectAndBroadSignalREvents() {
    signalRSocketHandler.subscribe("reconnect", async () => {
      await fetchAndStoreBunks();
    });
    signalRSocketHandler.subscribe("statuschange", async (status) => {
      if (status.connected && !bunks.value && !isLoading.value) {
        await fetchAndStoreBunks();
      }
    });
    signalRSocketHandler.on("ReceiveConfigurationChangeEvent", async () => {
      // Fetch all data again as there have been a CloudSync event
      await fetchAndStoreBunks();
    });

    subscribeToBunkConfigSignalRMessages();
  }

  async function addNewBunks(bunks: CabinBunkWriteModel[]): Promise<CabinBunk[]> {
    const resp = await customFetch(`${apiBaseUrl}/cabinBunks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bunks),
    });

    if (resp.ok) {
      const data = (await resp.json()) as CabinBunk[];
      pushToast({
        title: "Bunks created",
        body: `${bunks.length} bunks created`,
        type: ToastType.SUCCESS,
        duration: 10_000,
      });
      return data;
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error creating bunks",
        body: "An error occurred while creating bunks: " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  async function deleteBunks(bunkIds: Guid[]): Promise<void> {
    const resp = await customFetch(`${apiBaseUrl}/cabinBunks`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bunkIds),
    });

    if (resp.ok) {
      pushToast({
        title: `${bunkIds.length} bunks deleted`,
        type: ToastType.SUCCESS,
        duration: 10_000,
      });
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error deleting bunks",
        body: "An error occurred while deleting bunks: " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  async function addCabinBunkEntries(entry: CabinBunkEntryWriteModel[]): Promise<CabinBunkEntry[]> {
    const resp = await customFetch(`${apiBaseUrl}/cabinBunks/entries`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(entry),
    });

    if (resp.ok) {
      const data = (await resp.json()) as CabinBunkEntry[];
      pushToast({
        title: "Personnel assigned to bunk",
        body: `${data.length} personnel assigned to bunk`,
        type: ToastType.SUCCESS,
        duration: 10_000,
      });
      return data;
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error assigning person to bunk",
        body: resp.status + ": " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  /**
   * Used to update the current reservations of a bunk and future reservations
   * also used to assign a person to a bunk
   */
  async function updateCabinBunkEntries(cabinBunk: CabinBunkEntryWriteModel[]): Promise<CabinBunkEntry[]> {
    const resp = await customFetch(`${apiBaseUrl}/CabinBunks/entries`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cabinBunk),
    });

    if (resp.ok) {
      const data = (await resp.json()) as CabinBunkEntry[];
      pushToast({
        title: "Reservation information updated",
        body: `Reservation information updated`,
        type: ToastType.SUCCESS,
        duration: 7_000,
      });
      return data;
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error updating reservation information",
        body: "An error occurred while updating reservation information: " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  /**
   * Used to delete a reservation
   */
  async function deleteCabinBunkEntries(ids: string[]) {
    const resp = await customFetch(`${apiBaseUrl}/CabinBunks/entries`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    });

    if (resp.ok) {
      pushToast({
        title: "Reservation deleted",
        body: `Reservation deleted`,
        type: ToastType.SUCCESS,
        duration: 7_000,
      });
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error deleting reservation",
        body: "An error occurred while deleting reservation: " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  /**
   * Used to update cabin numbers, bunk numbers, disabled status and muster stations
   */
  async function updateCabinBunks(cabinBunks: CabinBunkUpdateModel[]): Promise<CabinBunk[]> {
    const resp = await customFetch(`${apiBaseUrl}/cabinBunks`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cabinBunks),
    });

    if (resp.ok) {
      const data = (await resp.json()) as CabinBunk[];
      pushToast({
        title: "Bunk updated",
        body: `Bunks updated`,
        type: ToastType.SUCCESS,
        duration: 7_000,
      });
      return data;
    } else {
      const errorResponse = await resp.text();
      pushToast({
        title: "Error updating bunk",
        body: "An error occurred while updating bunks: " + errorResponse,
        type: ToastType.ERROR,
        duration: 10_000,
      });
      throw new Error(errorResponse);
    }
  }

  return {
    isLoading,
    bunks,
    getBunksByIdFromCache,
    fetchAndStoreBunks,
    fetchBunkHistory,
    musterTypes,
    addNewBunks,
    deleteBunks,
    addCabinBunkEntries,
    updateCabinBunkEntries,
    deleteCabinBunkEntries,
    updateCabinBunks,
  };
}

async function fetchBunkHistory(bunkId: Guid): Promise<CabinBunkEntry[]> {
  const resp = await customFetch(`${apiBaseUrl}/cabinBunks/${bunkId}/history`, {
    method: "GET",
  });

  if (resp.ok) {
    return (await resp.json()) as CabinBunkEntry[];
  } else {
    throw new Error(await resp.text());
  }
}

export function generateNewBunks(conf: NewBunkSettings): CabinBunkWriteModel[] {
  const bunks: CabinBunkWriteModel[] = [];
  for (let cabinNr = conf.cabinNrFrom; cabinNr <= conf.cabinNrTo; cabinNr++) {
    for (let bunkNr = 0; bunkNr < conf.bunksPerCabin; bunkNr++) {
      bunks.push({
        cabinNr: cabinNr.toString(),
        bunkNr:
          conf.bunkNumberingMethod === BunkNumberingMethod.Number
            ? (bunkNr + 1).toString()
            : String.fromCharCode(65 + bunkNr),
        disabledDateTime: null,
        comment: null,
        defaultMusterStationPerMusterType: conf.defaultMusterStationPerMusterType,
      });
    }
  }
  return bunks;
}

function getBunksByIdFromCache(bunkIds: Guid[]): CabinBunk[] {
  return bunks.value?.filter((b) => bunkIds.includes(b.id)) ?? [];
}

export interface NewBunkSettings {
  cabinNrFrom: number;
  cabinNrTo: number;
  bunksPerCabin: number;
  bunkNumberingMethod: BunkNumberingMethod;
  defaultMusterStationPerMusterType: MusterStationPerMusterTypeWriteModel[];
}

export enum BunkNumberingMethod {
  Letter = "Letter",
  Number = "Number",
}

/**
 * Finds all current residents of a bunk based on the checkOut date of the history entries
 * NOTE: It is possible to have multiple people living in the same bunk at the same time
 * If checkIn is not null AND checkOut date is null, the person is still residing in the bunk
 * @param bunk
 * @returns
 */
export function getCurrentResidents(bunk: CabinBunk) {
  return sortList(
    bunk.cabinBunkHistory?.filter((h) => h.checkIn && h.checkOut === null) ?? [],
    "checkIn",
    SortDir.ASC,
  );
}
/**
 * Finds all future reservation of a bunk based on the
 * @param bunk
 * @returns
 */
export function getPlannedReservations(bunk: CabinBunk) {
  return sortList(bunk.cabinBunkHistory?.filter((h) => !h.checkIn) ?? [], "plannedCheckIn", SortDir.ASC);
}

export interface CabinBunk {
  id: Guid;
  cabinNr: string;
  bunkNr: string;

  /**
   * If not null, the bunk is disabled
   */
  disabledDateTime: string | Date | null;
  comment: string | null;
  /**
   * Referenced to ApiCabinBunkHistoryEntry
   * It is not always populated, so the frontend should be able to fetch it if it is not available
   */
  cabinBunkHistory: CabinBunkEntry[];
  /**
   * Currently calculated in frontend, could be moved to backend
   * Filters list of cabinBunkHistory
   */
  currentResidents?: CabinBunkEntry[] | null;
  /**
   * Calculated in frontend, filters list of cabinBunkHistory
   */
  plannedReservations?: CabinBunkEntry[] | null;
  lastUpdatedAt: Date | string;
  deletedUtcDateTime?: Date | string | null;
  defaultMusterStationPerMusterType: MusterStationPerMusterType[];
}

/**
 * Used when creating a new bunk
 */
export interface CabinBunkWriteModel {
  cabinNr: string;
  bunkNr: string;

  /**
   * If not null, the bunk is disabled
   */
  disabledDateTime: string | Date | null;
  comment: string | null;
  defaultMusterStationPerMusterType: MusterStationPerMusterTypeWriteModel[];
}

/**
 * Used when updating a bunk
 */
export interface CabinBunkUpdateModel extends CabinBunkWriteModel {
  id: Guid;
}

export interface MusterStationPerMusterType {
  id: Guid;
  musterTypeId: Guid;
  musterType: MusterType;
  primaryMusterStationId: Guid;
  primaryMusterStation: ApiMusterStation;
  secondaryMusterStationId: Guid | null;
  secondaryMusterStation: ApiMusterStation | null;
}

export interface MusterStationPerMusterTypeWriteModel {
  musterTypeId: Guid;
  primaryMusterStationId: Guid;
  secondaryMusterStationId?: Guid | null;
}

/**
 * You are probably looking for `CabinBunkEntry` or the write/update models.
 * This BaseModel is just for shared props, and should in normal cases not be used other places
 */
interface CabinBunkEntryBaseModel {
  cabinBunkId: Guid;
  /**
   * Can be null if backend has not included it, in that case you must fetch cabinBunk by cabinBunkId
   */
  cabinBunk: CabinBunk | null;
  /**
   * Referenced to ApiPerson
   */
  personId: Guid;
  /**
   * If checkIn is defined, entry has happened.
   * When we enmbark a person with assigned bunk, person is checked in and checkIn is set to current time.
   * When person is checked out, checkOut is set to current time.
   * PlannedCheckIn is set to the time the person is expected to check in.
   * PlannedCheckOut is set to the time the person is expected to check out.
   */
  checkIn: Date | string | null;
  checkOut: Date | string | null;
  plannedCheckIn: Date | string | null;
  plannedCheckOut: Date | string | null;
  comment?: string | null;
}

export interface CabinBunkEntryWriteModel extends CabinBunkEntryBaseModel {
  /**
   * Whether or not to copy default musterStatons from the bunk to the person
   * TODO: Must be added to backend.
   */
  overridePersonalMusterStations: boolean;
}

export interface CabinBunkEntryUpdateModel extends CabinBunkEntryBaseModel {
  id: Guid;
}

export interface CabinBunkEntry extends CabinBunkEntryUpdateModel {
  /**
   * Could be null and then the frontend is responsible for fetching the person, which should already be available in the frontend
   */
  person: ApiPerson | null;

  lastUpdatedAt: Date | string;
  deletedUtcDateTime?: Date | string | null;
}

export interface CabinBunkHistoryQuery {
  [key: string]: any; // Needed to make queryToParams work
  cabinBunkIds?: string[];
  from?: Date;
  to?: Date;
}

export async function exportBunkHistory(
  cabinBunkHistoryQuery: CabinBunkHistoryQuery,
  format: "xlsx" | "csv",
  fileName = "Bunk History",
) {
  const queryParamString = queryToParams(cabinBunkHistoryQuery, false);
  const resp = await customFetch(`${apiBaseUrl}/cabinBunks/entries${queryParamString}`, {
    method: "GET",
  });

  if (resp.ok) {
    const cabinBunkHistoryFromApi = (await resp.json()) as CabinBunkEntry[];
    const headersAndRows = getHeadersAndRowsFromCabinBunkHistory(
      cabinBunkHistoryFromApi,
      cabinBunkHistoryExportKnownProperties,
    );
    if (format === "xlsx") {
      headersAndRowsToSpreadsheet(headersAndRows.headers, headersAndRows.rows, fileName);
    } else {
      headersAndRowsToCsv(headersAndRows.headers, headersAndRows.rows, fileName);
    }
  } else {
    throw new Error(await resp.text());
  }
}

export function exportBunkStatus(bunks: CabinBunk[]) {
  const fileName = "Bunk Status" + new Date().toISOString();
  const bunksWithStatus =
    bunks?.map((bunk) => {
      let status;
      if (bunk.currentResidents?.length) {
        status = BunkStatus.OCCUPIED;
      } else if (bunk.disabledDateTime) {
        status = BunkStatus.DISABLED;
      } else {
        status = BunkStatus.AVAILABLE;
      }
      return {
        ...bunk,
        status,
      };
    }) ?? [];

  const headersAndRows = getHeadersAndRowsFromCabinBunk(
    bunksWithStatus,
    cabinBunkStatusExportKnownProperties,
  );
  headersAndRowsToSpreadsheet(headersAndRows.headers, headersAndRows.rows, fileName);
}

/**
 * Converts a list of cabin bunk history entries to a list of headers and rows
 */
export function getHeadersAndRowsFromCabinBunkHistory(
  cabinBunkEntries: CabinBunkEntry[],
  columns: CabinBunkExportProperty[],
): {
  headers: string[];
  rows: (string | number | null | undefined)[][];
} {
  return {
    headers: columns.map((c) => c.displayName),
    rows: cabinBunkEntries.map((cabinBunkEntry) => columns.map((c) => get(cabinBunkEntry, c.path ?? ""))),
  };
}

/**
 * Converts a list of cabin bunks to a list of headers and rows
 */
export function getHeadersAndRowsFromCabinBunk(
  cabinBunks: CabinBunk[],
  columns: CabinBunkExportProperty[],
): {
  headers: string[];
  rows: (string | number | null | undefined)[][];
} {
  return {
    headers: columns.map((c) => c.displayName),
    rows: cabinBunks.map((cabinBunk) =>
      columns.map((c) => {
        if (c.function) {
          return c.function(cabinBunk);
        } else if (c.path) {
          return get(cabinBunk, c.path);
        }
        throw new Error("Either path or function must be defined");
      }),
    ),
  };
}

/**
 * Exports the current status of the bunks
 */
export const cabinBunkStatusExportKnownProperties: CabinBunkExportProperty[] = [
  { displayName: "Cabin", path: "cabinNr" },
  { displayName: "Bunk", path: "bunkNr" },
  { displayName: "Status", path: "status" },
  {
    displayName: "Person Names",
    path: "fullName",
  },
  { displayName: "Roles", path: "roleName" },
  { displayName: "Companies", path: "company" },
  { displayName: "Shifts", path: "shift" },
  { displayName: "Check In Times", path: "checkIn" },
];

/**
 * Exports the history of the bunks
 */
export const cabinBunkHistoryExportKnownProperties: CabinBunkExportProperty[] = [
  { displayName: "Cabin", path: "cabinBunk.cabinNr" },
  { displayName: "Bunk", path: "cabinBunk.bunkNr" },
  {
    displayName: "Check In Time",
    function: (bunk) =>
      bunk.currentResidents
        ?.map((entry) => (entry.checkIn ? formatDate(entry.checkIn, "'UTC:' yyyy-MM-dd HH:mm") : null))
        .join(", \n"),
  },
  { displayName: "Check Out Time", path: "checkOut" },
  {
    displayName: "Person Name",
    function: (bunk) => bunk.currentResidents?.map((r) => r.person?.fullName).join(", \n"),
  },
  {
    displayName: "Role",
    function: (bunk) => bunk.currentResidents?.map((r) => r.person?.role?.name).join(", \n"),
  },
  {
    displayName: "Company",
    function: (bunk) => bunk.currentResidents?.map((r) => r.person?.company).join(", \n"),
  },
  {
    displayName: "Shift",
    function: (bunk) => bunk.currentResidents?.map((r) => r.person?.shift).join(", \n"),
  },
];

export type CabinBunkExportProperty = {
  displayName: string;
  path?: string;
  /**
   * Alternative method to get the value of the property, either path or function must be defined
   */
  function?: (value: CabinBunk) => string | undefined;
};

export enum BunkStatus {
  OCCUPIED = "Occupied",
  AVAILABLE = "Available",
  DISABLED = "Disabled",
}
