import { computed, ref } from "vue";
import { environment } from "../../environments/environment";
import SignalRSocketHandler from "@/helpers/signalRSocketHandler";
import customFetch from "@/helpers/customFetch";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";

const initialized = ref(false);
const baseUrl = `${environment.genericApiAddress}/siteLocation`;

/**
 * Latest location fetched from api or received via SignalR.
 * Can be used to identify age of data in backend
 */
const lastReceivedSiteLocation = ref<SiteLocation | null>(null);

export default function () {
  if (!initialized.value) {
    initialized.value = true;

    fetchSiteLocationsAndInitializeSignalR();
  }

  const gpsLocation = computed(() => lastReceivedSiteLocation.value || undefined);
  const isLocationDataOld = computed(() => {
    if (!gpsLocation.value?.timestampUtc) {
      return true;
    }

    const tenMinutesAgo = new Date(Date.now() - 10 * 60_000);
    return new Date(gpsLocation.value.timestampUtc) < tenMinutesAgo;
  });

  return {
    fetchSiteLocation,
    lastReceivedSiteLocation,
    gpsLocation,
    isLocationDataOld,
  };
}

export async function fetchSiteLocation(
  siteId?: string,
  query: {
    siteIds?: string;
    latest?: boolean;
    from?: Date | string;
    to?: Date | string;
    aggregationPeriod?: string;
  } = {
    siteIds: siteId,
    latest: false,
    from: new Date(new Date().getTime() - 48 * 60 * 60_1000), // 24 hours ago
    to: undefined,
    aggregationPeriod: "00:05:00",
  },
): Promise<SiteLocation[]> {
  try {
    const params = queryToParams(query);
    const res = await customFetch(`${baseUrl}/geo${params}`, {
      method: "GET",
    });
    if (res.ok) {
      return res.json() as Promise<SiteLocation[]>;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function fetchSiteLocationsAndInitializeSignalR() {
  initializeSignalRSubscriptions();
  SignalRSocketHandler.subscribe("statuschange", (status) => {
    if (status.connected) {
      initializeSignalRSubscriptions();
    }
  });
  const locations = await fetchSiteLocation();
  if (locations.length > 0) lastReceivedSiteLocation.value = locations[0];
}

function initializeSignalRSubscriptions() {
  SignalRSocketHandler.on("ReceiveGeoLocationChangeEvent", (location: SiteLocation) => {
    lastReceivedSiteLocation.value = location;
  });
}

export interface SiteLocation {
  id: string;
  timestampUtc: string;
  locationLngLat: [number, number];
  siteId: string | null;
  course: number | null;
  heading: number | null;
}
