var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"traffic-light-container",class:{
      'traffic-light-container--tablet': _vm.isTablet,
    }},[_c('div',{staticClass:"traffic-light"},[_c('span',{staticClass:"light red",class:[
          _vm.trafficLightStatus === _vm.TrafficLightStatus.RED ||
          _vm.trafficLightStatus === _vm.TrafficLightStatus.REDOVERRIDE
            ? 'active'
            : '',
        ]},[(_vm.trafficLightStatus === _vm.TrafficLightStatus.REDOVERRIDE)?_c('HandIcon',{attrs:{"size":"16px","iconColor":"var(--color-ui-01)"}}):_vm._e()],1),_c('span',{staticClass:"light-background"})]),_c('div',{staticClass:"traffic-light"},[_c('span',{staticClass:"light yellow",class:[_vm.trafficLightStatus === _vm.TrafficLightStatus.YELLOW ? 'active' : '']}),_c('span',{staticClass:"light-background"})]),_c('div',{staticClass:"traffic-light"},[_c('span',{staticClass:"light green",class:[_vm.trafficLightStatus === _vm.TrafficLightStatus.GREEN ? 'active' : '']}),_c('span',{staticClass:"light-background"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }