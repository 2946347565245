var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('GenericDataPill',{attrs:{"backgroundColor":_vm.gpsLocation?.timestampUtc ? 'var(--color-ui-05)' : 'var(--color-ui-04)'},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('GpsLocationIcon',{attrs:{"size":"16","iconColor":_vm.gpsLocation?.timestampUtc
          ? _vm.isLocationDataOld
            ? 'var(--color-status-alert)'
            : 'var(--color-pop-01)'
          : 'var(--color-brand-03)'}})]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"vessel-location-info-container"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.gpsLocation?.timestampUtc
            ? `${_vm.vesselLocation.label}${_vm.isTablet ? ' · ' + _vm.gpsLocation.timestampUtc : ''}`
            : 'NO GPS SOURCE'
        ),expression:"\n          gpsLocation?.timestampUtc\n            ? `${vesselLocation.label}${isTablet ? ' · ' + gpsLocation.timestampUtc : ''}`\n            : 'NO GPS SOURCE'\n        "}],staticClass:"location-label",style:({
          color: _vm.gpsLocation?.timestampUtc ? 'var(--color-brand-01)' : 'var(--color-brand-03)',
          width: _vm.isTablet ? '50px' : undefined,
        })},[_vm._v(" "+_vm._s(_vm.gpsLocation?.timestampUtc ? _vm.vesselLocation.label : "NO GPS SOURCE")+" ")]),(_vm.gpsLocation?.timestampUtc && !_vm.isTablet)?_c('ReactiveTimeSince',{staticClass:"time-since-text",class:{ 'old-data': _vm.isLocationDataOld },attrs:{"date":_vm.gpsLocation?.timestampUtc,"useShortForm":true}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }