var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"location-item",class:{
    expanded: _vm.location.locationName === _vm.selectedLocation,
  }},[_c('div',{staticClass:"bar-wrapper"},[_c('ListItemStatusBar',{staticStyle:{"height":"auto"},attrs:{"color":"var(--color-brand-06)"}}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"main-info",on:{"click":function($event){return _vm.$emit('locationClicked', _vm.location.locationName)}}},[_c('WorldLocationIcon',{staticStyle:{"flex-shrink":"0"},attrs:{"iconColor":"var(--color-brand-06)","size":"16"}}),_c('div',{staticClass:"left-info"},[_c('p',{staticClass:"location-name"},[_vm._v(_vm._s(_vm.location.locationName))]),(_vm.location.yellowLimit && _vm.location.redLimit)?_c('p',{staticClass:"personnel-limits"},[_vm._v(" Max personnel: "+_vm._s(_vm.location.yellowLimit)+"-"+_vm._s(_vm.location.redLimit)+" ")]):_vm._e()]),_c('div',{staticClass:"right-info"},[(_vm.location.connectedGangways.length > 0)?_c('GenericDataPill',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              `Gangway ${_vm.location.connectedGangways
                .map((g) => `'${g.label}'`)
                .join(', ')} connected to this location`
            ),expression:"\n              `Gangway ${location.connectedGangways\n                .map((g) => `'${g.label}'`)\n                .join(', ')} connected to this location`\n            "}],staticClass:"count-pill",scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('GangwayIcon',{attrs:{"size":"16","iconColor":_vm.location.connectedGangways.some((gangway) => gangway.trafficLight != null) &&
                  !_vm.location.yellowLimit &&
                  !_vm.location.redLimit
                    ? 'var(--color-status-warning)'
                    : 'var(--color-pop-01)'}})]},proxy:true}],null,false,2052960952)}):_vm._e(),_c('GenericDataPill',{staticClass:"count-pill",scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('PeopleIcon',{attrs:{"size":"16","iconColor":"var(--color-pop-01)"}})]},proxy:true},{key:"count",fn:function(){return [_vm._v(" "+_vm._s(_vm.location.personnel.length)+" ")]},proxy:true}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.location.locationName === _vm.selectedLocation),expression:"location.locationName === selectedLocation"}],staticClass:"extra-info"},[_vm._l((_vm.trafficLightPillStatuses),function(trafficLightStatus){return _c('SPill',{key:trafficLightStatus.id,attrs:{"title":trafficLightStatus.title,"secondary-data":"edit","icon":trafficLightStatus.icon,"icon-color":"var(--color-ui-01)","pill-text-color":"var(--color-ui-01)","clickable":true,"backgroundColor":trafficLightStatus.backgroundColor,"clickFunction":() => _vm.$emit('editLocationLimits')}})}),_c('ul',{staticClass:"personnel-list"},_vm._l((_vm.location.personnel),function(person){return _c('li',{key:person.id},[_c('PersonIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-06)"}}),_vm._v(" "+_vm._s(person.fullName)+" ")],1)}),0)],2)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.location.locationName === _vm.selectedLocation),expression:"location.locationName === selectedLocation"}],staticClass:"collapse-wrapper",on:{"click":function($event){return _vm.$emit('locationClicked', undefined)}}},[_c('div',{staticClass:"collapse"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }