import { render, staticRenderFns } from "./Gangway.vue?vue&type=template&id=286bc7be&scoped=true&"
import script from "./Gangway.vue?vue&type=script&lang=ts&"
export * from "./Gangway.vue?vue&type=script&lang=ts&"
import style0 from "./Gangway.vue?vue&type=style&index=0&id=286bc7be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286bc7be",
  null
  
)

export default component.exports