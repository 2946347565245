import customFetch from "../helpers/customFetch";
import { onMounted, ref } from "vue";
import signalRSocketHandler from "../helpers/signalRSocketHandler";
import { environment } from "../../environments/environment";
import { Guid } from "../typedef";
import { ApiMusterStation } from "scanreach-frontend-components/src/types/ApiNode.type";

const musterTypes = ref<MusterType[]>([]);
const isLoading = ref(false);

const initialLoaded = ref(false);
const apiBaseUrl = environment.apiAddress; // TODO: Change to genericApiAddress when the backend is updated

export default function () {
  onMounted(() => {
    if (!initialLoaded.value) {
      initialLoaded.value = true;

      if (process.env.NODE_ENV != "test") {
        fetchDataFromApiAndSubscribeToSignalR();
      }
    }
  });

  function fetchDataFromApiAndSubscribeToSignalR() {
    subscribeToReconnectAndBroadSignalREvents();
  }

  async function fetchAndStoreMusterTypes(): Promise<MusterType[]> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiBaseUrl}/musterTypes`, {
        method: "GET",
      });

      if (resp.ok) {
        const data = (await resp.json()) as MusterType[];

        musterTypes.value = data;
        return musterTypes.value;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  function subscribeToReconnectAndBroadSignalREvents() {
    signalRSocketHandler.subscribe("reconnect", () => {
      fetchAndStoreMusterTypes();
    });
    signalRSocketHandler.subscribe("statuschange", (status) => {
      if (status.connected && !musterTypes.value && !isLoading.value) {
        fetchAndStoreMusterTypes();
      }
    });
    signalRSocketHandler.on("ReceiveConfigurationChangeEvent", () => {
      // Fetch all data again as there have been a CloudSync event
      fetchAndStoreMusterTypes();
    });
  }

  return {
    isLoading,
    musterTypes,
  };
}

export interface MusterType {
  id: Guid;
  label: string;
  defaultActiveMusterStations?: ApiMusterStation[];
}
