import { ApiNode } from "scanreach-frontend-components/src/types/ApiNode.type";
import { Guid } from "scanreach-frontend-components/src/types/Issue.type";
import { ApiPerson, ApiPersonAction } from "./personTypes";

type GangwaySharedProps = {
  label: string;
  rssiLimit: number | null;
  x: number | null;
  y: number | null;
  active: boolean;
  approvalMode: boolean;
  customGangwayLocation: string | null;
};

export type Gangway = GangwaySharedProps & {
  id: Guid;
  onboardingNodes: ApiNode[];
  dropoffNodes: ApiNode[];
  deletedUtcDateTime?: Date | null;
  gangwayConfigurationStatus: GangwayConfigurationStatus;
  trafficLight?: TrafficLight;
};

export enum GangwayConfigurationStatus {
  PENDING = "pending",
  /**
   * Currently not in use anywhere
   */
  ERROR = "error",
  OK = "ok",
}

export type TrafficLight = {
  id: Guid;
  isActive: boolean;
  comment: string | null;
  label: string;
  dropOffLocation: GangwayLocation | null;
  gangwayId: Guid | null;
};

export type TrafficLightStatusEvent = {
  trafficLightId: Guid;
  status: TrafficLightStatus;
};

export enum TrafficLightStatus {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
  /**
   * Emergency stop (redOverride), will override all other states
   */
  REDOVERRIDE = "redOverride",
}

export const TrafficLightColorHexMap: { [key in TrafficLightStatus]: string } = {
  [TrafficLightStatus.RED]: "var(--color-status-alarm)",
  [TrafficLightStatus.REDOVERRIDE]: "var(--color-status-alarm)",
  [TrafficLightStatus.YELLOW]: "var(--color-status-warning)",
  [TrafficLightStatus.GREEN]: "#2be94d",
};

export const TrafficLightTextHexMap: { [key in TrafficLightStatus]: string } = {
  [TrafficLightStatus.RED]: "Stop",
  [TrafficLightStatus.REDOVERRIDE]: "Emergency stop",
  [TrafficLightStatus.YELLOW]: "Wait",
  [TrafficLightStatus.GREEN]: "Walk",
};

export type GangwayWriteModel = GangwaySharedProps & {
  id: Guid | null;
  onboardingNodeMacs: string[];
  dropoffNodeMacs: string[];
  trafficLightId?: Guid | null;
};

export type GangwayAction = {
  id: Guid;
  personId: Guid | null;
  location?: string | null;
  direction: GangwayDirection;
  state: GangwayEventState;
  dateTimeUtc: Date;
  deletedUtcDateTime?: Date | null;
  gangway?: Gangway | null;
};

export type GangwayActionWithPerson = GangwayAction & {
  person: ApiPerson | null;
};

export enum GangwayDirection {
  OnBoard = "onBoard",
  OffBoard = "offBoard",
}

export enum GangwayEventState {
  Unhandled = "unhandled",
  Approved = "approved",
  Rejected = "rejected",
}

export type PersonActionWithPerson = ApiPersonAction & {
  person: ApiPerson | null;
};

export type GangwayLocationWrapper = {
  /** These are the available custom vessel locations */
  gangwayLocations: GangwayLocation[];
  /** These locations contains geolocation and is not editable onprem */
  spatialLocations: SpatialDataObjectGroup[];
};

export type GangwayLocation = {
  id: Guid;
  label: string;
  yellowLimit?: number | null;
  redLimit?: number | null;
  deletedUtcDateTime?: Date | null;
};

export type GangwayLocationWithNewProp = GangwayLocation & { new?: boolean; combinedLabel?: string };

export type VesselLocation = {
  id: Guid; // Not the same as Gangwaylocation
  label: string; // Example: "Arcadis Ost - A64"
  dateTimeUtc: Date;
};

export type SpatialDataObjectGroup = {
  id: Guid;
  label: string;
  /**
   * GeoJson string with Polygon
   */
  polygon: null | string;
  spatialDataObjects: SpatialDataObject[];
  /**
   * Will always be true for now as the backend will only return groups that are enabled
   */
  isGroupEnabled: boolean;
};

export type SpatialDataObject = {
  id: Guid;
  /**
   * The id of the SpatialDataObjectGroup this object belongs to
   */
  groupId: Guid;
  label: string;
  /**
   * GeoJson string with Point
   */
  location: string;
  /**
   * Used to determine what the type is and how we should render it
   */
  type: string | null;
};
