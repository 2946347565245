import { DashboardNode, useStore } from "@/store/desktopStore";
import { computed } from "vue";

// TODO: Maybe fetch muster stations from backend when backend is updated
export default function () {
  const store = useStore();
  const nodes = computed(() => store.getters.nodes as DashboardNode[]);
  const musterStations = computed(() =>
    nodes.value.filter((node) => node.musterStation).map((node) => node.musterStation),
  );

  return {
    musterStations,
  };
}
