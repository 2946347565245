var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bunk-summary"},[_vm._m(0),_c('div',{staticClass:"bunk-chart-info"},[_c('chart-donut',{attrs:{"initialValues":_vm.countValues}}),_c('ul',{staticClass:"info-list info-bunks"},_vm._l((_vm.countValues),function(bunkStatus){return _c('li',{key:'bunkStatus-' + bunkStatus.name},[_c('div',{staticClass:"category-circle",style:({ background: bunkStatus.color })}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: bunkStatus.name,
            delay: {
              show: 500,
            },
          }),expression:"{\n            content: bunkStatus.name,\n            delay: {\n              show: 500,\n            },\n          }"}],staticClass:"label"},[_vm._v(" "+_vm._s(bunkStatus.name)+" ")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(bunkStatus.count))])])}),0),_c('div',{staticClass:"export-current-bunk-state-container"},[_c('NormalButton',{attrs:{"primary":""},nativeOn:{"click":function($event){return _vm.exportBunkStatus(_vm.bunks)}}},[_c('ShareIcon',{attrs:{"iconColor":"var(--color-pop-01)","size":"16"}}),_vm._v("Export bunks current state")],1)],1)],1),_c('div',{staticClass:"additional-info-section"},[_c('ul',{staticClass:"info-list"},_vm._l((_vm.additionalInfo),function(item){return _c('li',{key:item.name},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.count))])])}),0),_c('div',{staticClass:"button-container"},[_c('BunksExporter',{attrs:{"bunks":_vm.bunks,"selectedBunkIds":_vm.selectedBunkIds}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"summary-header"},[_c('div',{staticClass:"header-and-sync-container"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("Bunk Summary")])])])])
}]

export { render, staticRenderFns }