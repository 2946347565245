var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',[_c('SLabel',[_vm._v("NAME"),_c('sup',[_vm._v("*")])]),_c('SInput',{staticStyle:{"margin-top":"8px"},attrs:{"value":_vm.modifiedGangway.label,"placeholder":"Enter a unique name for this gangway","maxlength":30,"required":""},on:{"input":(val) => (_vm.modifiedGangway.label = val)}})],1),_c('div',[_c('SLabel',[_vm._v("MODE"),_c('sup',[_vm._v("*")])]),_c('SSelect',{staticStyle:{"width":"100%","margin-top":"8px"},attrs:{"options":[
        {
          value: _vm.FrontendGangwayMode.AUTO,
          displayName: 'Auto',
          description: 'Detections are automatically approved',
          isEnabled: true,
        },
        {
          value: _vm.FrontendGangwayMode.MANUAL,
          displayName: 'Manual',
          description: 'Each detection must be manually approved',
          isEnabled: true,
        },
      ],"placeholder":"Select one mode","modelValue":_vm.selectedGangwayMode},on:{"update:modelValue":(selectedValue) => {
          _vm.selectedGangwayMode = selectedValue;
        }}})],1),_c('div',[_c('SLabel',[_vm._v("SENSITIVITY"),_c('sup',[_vm._v("*")])]),_c('SSelect',{staticStyle:{"width":"100%","margin-top":"8px"},attrs:{"options":_vm.sensitivityLevelOptions,"placeholder":"Select a sensitivity level","modelValue":_vm.sensitivityRSSILimit,"isDisabled":_vm.isCustomRSSIShowing},on:{"update:modelValue":(selectedValue) => {
          _vm.sensitivityRSSILimit = selectedValue;
        }}}),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end"}},[_c('NormalButton',{attrs:{"quaternary":""},nativeOn:{"click":function($event){_vm.isCustomRSSIShowing = !_vm.isCustomRSSIShowing}}},[_vm._v(" "+_vm._s(_vm.isCustomRSSIShowing ? "Cancel custom" : "Set custom")+" ")])],1),(_vm.isCustomRSSIShowing)?_c('SInput',{attrs:{"value":_vm.customRSSILimit,"type":"number","min":-99,"max":-1,"placeholder":"Enter a custom RSSI threshold (dB)","required":""},on:{"input":_vm.handleNewRSSIValue}}):_vm._e()],1),_c('div',[_c('SLabel',[_vm._v("EMBARK NODES"),_c('sup',[_vm._v("*")])]),_c('ul',{staticClass:"connected-nodes-list"},[_c('ConfigConnectedNodeSelection',{attrs:{"nodes":_vm.availableNodes},on:{"nodeSelect":(nodeMac) => _vm.handleAddNodeMac(nodeMac, true)}}),_vm._l((_vm.modifiedGangway.onboardingNodeMacs),function(mac){return _c('ConfigConnectedNode',{key:mac,attrs:{"label":mac},on:{"remove":function($event){return _vm.handleRemoveNodeMac(mac, true)}}})})],2)],1),_c('div',[_c('SLabel',[_vm._v("DROP-OFF NODES"),_c('sup',[_vm._v("*")])]),_c('ul',{staticClass:"connected-nodes-list"},[_c('ConfigConnectedNodeSelection',{attrs:{"nodes":_vm.availableNodes},on:{"nodeSelect":(nodeMac) => _vm.handleAddNodeMac(nodeMac, false)}}),_vm._l((_vm.modifiedGangway.dropoffNodeMacs),function(mac){return _c('ConfigConnectedNode',{key:mac,attrs:{"label":mac},on:{"remove":function($event){return _vm.handleRemoveNodeMac(mac, false)}}})})],2)],1),_c('div',[_c('SLabel',[_vm._v(" Traffic-light system "),_c('QuestionMarkIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: `<center style='width: 300px'>Connect this gangway to available traffic-light hardware on board to assist with personnel transfers, or leave empty if no such system is needed.</center>`,
          html: true,
        }),expression:"{\n          content: `<center style='width: 300px'>Connect this gangway to available traffic-light hardware on board to assist with personnel transfers, or leave empty if no such system is needed.</center>`,\n          html: true,\n        }"}],staticClass:"question-mark-tooltip",attrs:{"size":"12","iconColor":"var(--color-brand-03)"}})],1),_c('VSelect',{staticStyle:{"width":"100%","margin-top":"8px"},attrs:{"options":_vm.trafficLights,"clearable":"","label":"label","placeholder":"Select traffic-light hardware, if desirable","reduce":(trafficLight) => trafficLight.id,"selectedLabel":(trafficLightId) => {
          const selectedTrafficLight = _vm.trafficLights.find((tl) => tl.id === trafficLightId);
          return selectedTrafficLight ? selectedTrafficLight.label : trafficLightId;
        }},model:{value:(_vm.modifiedGangway.trafficLightId),callback:function ($$v) {_vm.$set(_vm.modifiedGangway, "trafficLightId", $$v)},expression:"modifiedGangway.trafficLightId"}})],1),_c('div',{staticClass:"location-section"},[_c('ToggleSetting',{staticStyle:{"padding-top":"0px"},attrs:{"isActive":_vm.isGangwayDropoffLocationSetToManual,"icon":_vm.EditIcon,"text":"Manually set gangway's drop-off location"},on:{"update:isActive":(val) => (_vm.isGangwayDropoffLocationSetToManual = val)}}),(_vm.isGangwayDropoffLocationSetToManual)?_c('div',{staticClass:"manual-location-section"},[_c('div',[(_vm.gpsLocation?.timestampUtc)?_c('SLabel',[_vm._v("Vessel location "),_c('QuestionMarkIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: `<center style='width: 300px'>This is the GPS location we recive from the NMEA node. This will show - unless vessel is inside a radius of 200 meters from a GPS location that has been provided to ScanReach and added to the system.</center>`,
              html: true,
            }),expression:"{\n              content: `<center style='width: 300px'>This is the GPS location we recive from the NMEA node. This will show - unless vessel is inside a radius of 200 meters from a GPS location that has been provided to ScanReach and added to the system.</center>`,\n              html: true,\n            }"}],staticClass:"question-mark-tooltip",attrs:{"size":"12","iconColor":"var(--color-brand-03)"}})],1):_vm._e(),(_vm.gpsLocation?.timestampUtc)?_c('div',{staticClass:"vessel-gps-location"},[_c('p',[_vm._v(_vm._s(_vm.vesselLocation?.label))]),_c('GpsLocationIcon',{attrs:{"size":"16px","iconColor":_vm.isLocationDataOld ? 'var(--color-status-alert)' : 'var(--color-pop-03)'}})],1):_vm._e()],1),_c('SelectGangwayLocation',{attrs:{"gangwayLocations":_vm.gangwayLocations,"modelValue":_vm.selectedGangwayLocation,"label":"Drop-off location","vesselLocation":_vm.vesselLocation?.label},on:{"update:modelValue":(l) => _vm.handleGangwayLocationUpdate(l)}}),(_vm.modifiedGangway.trafficLightId)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"24px"}},[_c('SInput',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Yellow-light Personnel Limit *","value":_vm.editedLocationObject ? _vm.editedLocationObject.yellowLimit : undefined,"type":"number","min":0,"max":_vm.editedLocationObject && _vm.editedLocationObject.redLimit
              ? _vm.editedLocationObject.redLimit - 1
              : undefined,"placeholder":"Enter the personnel limit for yellow light","required":""},on:{"input":(val) => {
              if (_vm.editedLocationObject) {
                _vm.editedLocationObject = { ..._vm.editedLocationObject, yellowLimit: parseInt(val) };
              }
            }}}),_c('SInput',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Red-light Personnel Limit *","value":_vm.editedLocationObject ? _vm.editedLocationObject.redLimit : undefined,"type":"number","min":_vm.editedLocationObject && _vm.editedLocationObject.yellowLimit
              ? _vm.editedLocationObject.yellowLimit + 1
              : undefined,"placeholder":"Enter the personnel limit for red light","required":""},on:{"input":(val) => {
              if (_vm.editedLocationObject) {
                _vm.editedLocationObject = { ..._vm.editedLocationObject, redLimit: parseInt(val) };
              }
            }}})],1):_vm._e()],1):_vm._e()],1),_vm._m(0),_c('div',{staticClass:"buttons"},[_c('NormalButton',{attrs:{"icon":"","type":"submit","disabled":!_vm.isFormSaveEnabled || _vm.isConfigBeingSaved}},[(!_vm.isConfigBeingSaved)?_c('SaveIcon',{attrs:{"size":"16","iconColor":_vm.isFormSaveEnabled ? 'var(--color-pop-01)' : 'var(--color-brand-03)'}}):_c('SyncIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-03)","isAnimated":""}}),_vm._v(" SAVE ")],1),_c('NormalButton',{attrs:{"tertiary":"","icon":"","formnovalidate":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('cancelIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-01)"}}),_vm._v(" CANCEL ")],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-helper-text"},[_c('sup',[_vm._v("*")]),_vm._v("required fields")])
}]

export { render, staticRenderFns }