import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import { fetchAlarmSilenceTime, putAlarmSilenceTime } from "@/helpers/apiService";
import { Issue } from "scanreach-frontend-components/src/types/Issue.type";

type AlarmSoundState = {
  silenceTime: Date | null;
};
const initialState: AlarmSoundState = {
  silenceTime: null,
};

interface Getters {
  silenceTime: Date | null;
  getAlarmsThatNeedsToPlaySound: Issue[];
}

interface AlarmSilenceMessage {
  alarmSilenceTimeUtc: Date;
}

const AlarmSoundModule = {
  state: () => initialState,
  mutations: {
    SET_SILENCE_TIME(state: AlarmSoundState, silenceTime: Date | null) {
      state.silenceTime = silenceTime == new Date("0001-01-01T00:00:00") ? null : silenceTime; // Check if silenceTime is equal to c# DateTime.MinValue
    },
  },
  getters: {
    silenceTime: (state: AlarmSoundState): Date | null => {
      return state.silenceTime;
    },
    getAlarmsThatNeedsToPlaySound: (state: AlarmSoundState, rootGetters: any): Issue[] => {
      return ([...rootGetters.visibleIssues, ...rootGetters.distressAlarms] as Issue[]).filter(
        (issue) => issue.activatedTime > (state.silenceTime as Date),
      );
    },
  },
  actions: {
    fetchSilenceTimeFromAPI: ({ commit }: { commit: any }) => {
      fetchAlarmSilenceTime()
        .then((alarmSilenceTime) => {
          commit("SET_SILENCE_TIME", alarmSilenceTime);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    silenceAlarms: ({ commit, getters }: { commit: any; getters: Getters }) => {
      if (getters.getAlarmsThatNeedsToPlaySound.length > 0) {
        commit("SET_SILENCE_TIME", new Date().toISOString());
        // 2020-12-15T09:30:53.7570828Z
        putAlarmSilenceTime().catch((error) => {
          console.error(error);
        });
      }
    },
    subscribeToSilenceTime: ({ commit }: { commit: any }) => {
      signalRSocketHandler.connect();
      signalRSocketHandler.on("ReceiveAlarmSilence", (alarmSilenceMessage: AlarmSilenceMessage) => {
        commit("SET_SILENCE_TIME", alarmSilenceMessage.alarmSilenceTimeUtc);
      });
    },
  },
};

export default AlarmSoundModule;
