import Vue from "vue";
import VueRouter from "vue-router";
import NodeDetailsList from "@/components/desktop/NodeDetailsList";
import NodeAdminList from "@/components/desktop/NodeAdminList";
import MapNodePlacementList from "@/components/desktop/NodeAdmin/MapNodePlacementList";
import MeshNodeDebugDetails from "@/components/desktop/NodeAdmin/MeshNodeDebugDetails";
import WearableAdmin from "@/views/desktop/WearableAdmin";
import PeopleAdmin from "@/views/desktop/PeopleAdmin";
import BunkAdmin from "@/views/desktop/BunkAdmin";
import Safety from "@/views/desktop/Safety";
import NodeAdmin from "@/views/desktop/NodeAdmin";
import Authentication from "@/views/Authentication";
import DesktopWrapper from "@/views/desktop/DesktopWrapper";
import UserAdmin from "@/views/desktop/UserAdmin";
import SystemStatus from "@/views/desktop/SystemStatus";
import Gangway from "@/views/desktop/Gangway";
import { RoleName } from "@/typedef";
import { destroySession, existsSession, getSession } from "@/CustomVueSession";
Vue.use(VueRouter);

// Configured to work with at root of directory ip:port/

/**
 * @typedef {{[key: string]: string}} RouteName
 */
export const RouteName = {
  Safety: "safety",
  Node: "node",
  Event: "event",
  NodeAdmin: "nodeadmin",
  NodeAdminDetails: "nodeadmindetails",
  NodePlacement: "nodeplacement",
  NodeDebugging: "nodedebugging",
  WearableAdmin: "wearableadmin",
  PeopleAdmin: "peopleadmin",
  BunkAdmin: "bunkadmin",
  UserAdmin: "useradmin",
  Gangway: "gangway",
  SystemStatus: "status",
  SystemStatusDetails: "statusdetails",
  Authentication: "authentication",
};

let defaultRedirectRouteName = RouteName.Safety;

/**@type {RouteConfig[]} */
const routes = [
  { path: "/", redirect: "/safety" },
  {
    path: "/safety",
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    component: DesktopWrapper,
    children: [
      {
        path: "",
        component: Safety,
        name: RouteName.Safety,
        props: true,
        children: [
          {
            path: "node/:nodeMac/:selectedWearable?",
            component: NodeDetailsList,
            props: true,
            name: RouteName.Node,
          },
        ],
      },
    ],
  },
  {
    path: "/status",
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    component: DesktopWrapper,
    children: [
      {
        path: "",
        name: RouteName.SystemStatus,
        component: SystemStatus,
        props: true,
      },
      {
        path: "issue/:selectedIssueId",
        name: RouteName.SystemStatusDetails,
        component: SystemStatus,
        props: true,
      },
    ],
  },
  {
    path: "/login",
    name: RouteName.Authentication,
    component: Authentication,
  },
  {
    path: "/nodeadmin",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin, RoleName.configurator],
    },
    children: [
      {
        path: "/",
        component: NodeAdmin,
        props: true,
        children: [
          { path: "/", name: RouteName.NodeAdmin, component: NodeAdminList, props: true },
          { path: "node/:nodeMac", component: NodeAdminList, props: true, name: RouteName.NodeAdminDetails },
          {
            path: "placement/:nodeMac", // FIXME: rewrite to node/:nodeMac/placement ?
            component: MapNodePlacementList,
            props: true,
            name: RouteName.NodePlacement,
          },
          {
            path: "debug/:nodeMac", // FIXME: rewrite to node/:nodeMac/debug ?
            component: MeshNodeDebugDetails,
            props: true,
            name: RouteName.NodeDebugging,
          },
        ],
      },
    ],
  },
  {
    path: "/wearableadmin",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    children: [
      {
        path: "/",
        name: RouteName.WearableAdmin,
        component: WearableAdmin,
      },
    ],
  },
  {
    path: "/peopleadmin",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    children: [
      {
        path: "/",
        name: RouteName.PeopleAdmin,
        component: PeopleAdmin,
      },
    ],
  },
  {
    path: "/bunkadmin",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    children: [
      {
        path: "/",
        name: RouteName.BunkAdmin,
        component: BunkAdmin,
      },
    ],
  },
  {
    path: "/useradmin",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin],
    },
    children: [
      {
        path: "/",
        name: RouteName.UserAdmin,
        component: UserAdmin,
      },
    ],
  },
  {
    path: "/gangway",
    component: DesktopWrapper,
    meta: {
      roles: [RoleName.admin, RoleName.operator],
    },
    children: [
      {
        path: "/",
        name: RouteName.Gangway,
        component: Gangway,
      },
    ],
  },
  {
    // Catch all route
    path: "*",
    redirect: "/safety",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === RouteName.Authentication) {
    if (existsSession()) {
      next(from ? from.path : next({ name: defaultRedirectRouteName }));
    } else {
      next();
    }
  } else {
    if (existsSession()) {
      // Initially set the defaultRedirectPath when logging in
      const userRoles = getAuthenticatedUserRoles();
      if (from.name == RouteName.Authentication) {
        defaultRedirectRouteName = getFirstValidRouteNameForRoles(userRoles);
      }
      if (!checkIfUserRolesIsAuthorizedToVisitRoute(userRoles, to)) {
        defaultRedirectRouteName = getFirstValidRouteNameForRoles(userRoles);
        console.warn(
          `Not allowed to visit ${
            to.fullPath
          } with roles: ${userRoles.toString()} Therefor going to ${defaultRedirectRouteName}`,
        );

        next({
          name: defaultRedirectRouteName,
        });
      } else {
        next();
      }
    } else {
      next({
        name: RouteName.Authentication,
        params: {
          message: to.redirectedFrom ? "" : "Please, login to access the requested page",
          redirect: to.path,
        },
      });
    }
  }
});

/**
 * @returns {RoleName[]}
 */
export function getAuthenticatedUserRoles() {
  /**@type {User} */
  const userSession = getSession("user");
  if (userSession) {
    return userSession.roles;
  } else {
    return [];
  }
}

/**
 * Get first valid route name for role
 * @param {RoleName[]} roles
 * @returns {string} Path to first valid path
 */
function getFirstValidRouteNameForRoles(roles) {
  /**@type {{ [roleName: string]: string }} */
  const firstValidRouteNameForRole = {
    [RoleName.admin]: RouteName.Safety,
    [RoleName.operator]: RouteName.Safety,
    [RoleName.configurator]: RouteName.NodeAdmin,
  };

  for (const role of Object.keys(firstValidRouteNameForRole)) {
    if (roles.includes(role)) {
      return firstValidRouteNameForRole[role];
    }
  }

  destroySession();
  return RouteName.Authentication;
}

/**
 * Check if userRoles is allowed to visit route
 * @param {RoleName[]} roles
 * @param {Route} route
 */
function checkIfUserRolesIsAuthorizedToVisitRoute(roles, route) {
  if (route.meta.roles) {
    return route.meta.roles.some((routeRole) => roles.includes(routeRole));
  } else {
    // Meta.roles does not exists, then check all it's parents if user is allowed to visit this route
    // Recursively check all meta.roles from top parent down to route based on routes defined above.
    let allowed = true;

    route.matched.forEach((r) => {
      if (r.meta.roles) {
        allowed = r.meta.roles.some((routeRole) => roles.includes(routeRole));
      }
    });
    return allowed;
  }
}

export default router;
