<template>
  <div v-if="needRefresh">
    <button class="refresh-button" @click="ReloadApp">
      <img src="@/assets/icon/refresh - dark.svg" alt srcset width="16" height="16" />
      Update available. Please click here to refresh. Auto refresh in {{ timeToAutoRefreshSeconds }}sec
    </button>
  </div>
</template>

<script>
export default {
  name: "VersionChecker",

  data() {
    return {
      needRefresh: false,
      readInterval: null,
      needRefreshInterval: null,
      activeVersion: "",
      timeToAutoRefreshSeconds: 10 * 60,
      timerIntervalMs: 30 * 60 * 1000,
    };
  },

  methods: {
    ReloadApp() {
      window.location.reload(true);
    },

    VerifyVersion() {
      this.ReadShaFileContent()
        .then((content) => {
          if (!this.activeVersion) {
            this.activeVersion = content;
          }

          if (this.IsRefreshNeeded(content)) {
            this.setNeedRefresh();
          }
        })
        .catch((error) => console.error(error));
    },

    setNeedRefresh() {
      // If first time setting needRefresh => start countdown
      if (!this.needRefresh) {
        this.needRefreshInterval = setInterval(() => {
          this.timeToAutoRefreshSeconds -= 1;
          if (this.timeToAutoRefreshSeconds <= 0) {
            this.ReloadApp();
          }
        }, 1000);
      }
      this.needRefresh = true;
    },

    ReadShaFileContent() {
      const url = this.$router.options.base + "index.html.sha256sum?currentTime=" + new Date().getTime();
      return fetch(url)
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          return text;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    IsRefreshNeeded(versionReadFromFile) {
      if (!versionReadFromFile) {
        return false;
      }
      if (this.activeVersion == versionReadFromFile) {
        return false;
      }
      return true;
    },
  },

  mounted() {
    this.readInterval = setInterval(() => {
      this.VerifyVersion();
    }, this.timerIntervalMs);
  },

  created() {
    // Listen for service-worker updates
    document.addEventListener("swUpdated", this.setNeedRefresh, { once: true });
  },

  beforeDestroy() {
    if (this.readInterval !== null) {
      clearInterval(this.readInterval);
    }
    if (this.needRefreshInterval !== null) {
      clearInterval(this.needRefreshInterval);
    }
    document.removeEventListener("swUpdated", this.setNeedRefresh);
  },
};
</script>
<style scoped>
.refresh-button {
  position: relative;
  z-index: 3;
  background: var(--color-status-warning);
  max-width: 100%;
  padding-left: 10px;
  height: 30px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #21282e;
  cursor: pointer;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}

.refresh-button > img {
  margin-right: 8px;
}
</style>
