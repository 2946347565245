import { PersonActionName, RoleName } from "./../typedef";
import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";
import { CustomFetchOptions, Guid } from "@/typedef";
import {
  ApiPerson,
  ApiPersonRole,
  ExternalSyncResultReadModel,
  PersonGroup,
  PersonWriteModel,
} from "@/types/personTypes";

const apiAddress = environment.apiAddress;

/**
 * Get all people
 * @returns {Promise<ApiPerson[]>}
 */
export async function fetchPersons({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiPerson[]> {
  const response = await customFetch(
    `${apiAddress}/persons`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  );
  return await response.json();
}

/**
 * Get person by id
 * @returns {Promise<ApiPerson>}
 */
export async function fetchPerson(personId: Guid): Promise<ApiPerson[]> {
  const response = await customFetch(`${apiAddress}/persons/${personId}`, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
}

/**
 * Put person by id
 * @returns {Promise<void>}
 */
export function putPerson(person: ApiPerson): Promise<void> {
  return new Promise((resolve, reject) => {
    const body: PersonWriteModel = {};

    if (!person.externalPersonReference?.isExternalSystemOwnerOfData) {
      body.fullName = person.fullName;
      body.dateOfBirth = person.dateOfBirth;
      body.cabinNr = person.cabinNr;
      body.bunkNr = person.bunkNr;
      body.company = person.company;
      body.comment = person.comment;
      body.shift = person.shift;
      body.group = person.group;
      body.roleName = person.role?.name;
    }
    if (!person.externalPersonReference?.isExternalSystemResponsibleForConnectingWearable) {
      body.tagId = person.wearable?.id;
    }

    return customFetch(`${apiAddress}/persons/${person.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Add a new person
 * @returns {Promise<Person>}
 */
export function postPerson(person: PersonWriteModel): Promise<ApiPerson> {
  return new Promise((resolve, reject) => {
    const body: PersonWriteModel = {
      fullName: person.fullName,
      dateOfBirth: person.dateOfBirth,
      company: person.company,
      comment: person.comment,
      shift: person.shift,
      group: person.group,
      cabinNr: person.cabinNr,
      bunkNr: person.bunkNr,
      roleName: person.roleName,
      tagId: person.tagId,
    };
    return customFetch(`${apiAddress}/persons`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(person),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .then((newPerson: ApiPerson) => {
        resolve(newPerson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Delete person by id
 * @returns {Promise<void>}
 */
export function deletePerson(personId: Guid): Promise<void> {
  return new Promise((resolve, reject) => {
    return customFetch(`${apiAddress}/persons/${personId}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          res.text().then((errorMsg) => {
            reject(res.status + " " + errorMsg);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Fetch all person roles
 * @return {Promise<ApiPersonRole[]>} A promise with roles as an array of strings
 */
export function fetchPersonRoles({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiPersonRole[]> {
  return new Promise((resolve, reject) => {
    try {
      customFetch(
        apiAddress + "/personroles",
        {
          method: "GET",
          credentials: "include",
        },
        showWarning,
      )
        .then((res) => res.json())
        .then((roles: ApiPersonRole[]) => {
          resolve(roles);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * POSTs a new person action
 *
 * @param {} actions
 * @returns {} Returns a fetch response. Remember to catch errors.
 */
export async function performPersonAction(
  actions: { personId: Guid; action: PersonActionName; location?: string | null }[],
): Promise<ApiPerson[]> {
  const result = await customFetch(`${apiAddress}/persons/actions`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(actions),
  });
  if (result.ok) {
    return (await result.json()) as ApiPerson[];
  }
  console.error("Error: " + result.status);
  throw new Error("Error, failed to post personAction: " + result.status);
}

/**
 * Update location of a existing personAction
 *
 * @param {} personActionId Guid id
 * @param {} [location] Location to change to
 * @returns {} Returns a fetch response. Remember to catch errors.
 */
export async function updatePersonActionLocation(
  personActionId: Guid,
  location?: string | null,
): Promise<void> {
  const body: {
    location?: string | null;
  } = {
    location,
  };
  const resp = await customFetch(`${apiAddress}/personActions/${personActionId}`, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (resp.ok) {
    return;
  } else {
    resp.text().then((errorMsg) => {
      throw new Error(resp.status + " " + errorMsg);
    });
  }
}

/**
 * Get sync status from external system
 * @returns {Promise<ExternalSyncResultReadModel>}
 */
export async function fetchExternalSystemSyncStatus(): Promise<ExternalSyncResultReadModel> {
  const response = await customFetch(`${apiAddress}/persons/sync/status`, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
}

/**
 * Fetch all person groups
 */
export async function fetchPersonGroups(): Promise<PersonGroup[]> {
  const response = await customFetch(`${apiAddress}/persons/personGroups`, {
    method: "GET",
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Error fetching person groups");
  }
  return await response.json();
}

export async function postPersonGroup(groupLabel: string): Promise<PersonGroup> {
  const response = await customFetch(`${apiAddress}/persons/personGroups/${groupLabel}`, {
    method: "POST",
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Error adding person group");
  }
  return await response.json();
}

export async function deletePersonGroup(groupLabel: string): Promise<void> {
  const response = await customFetch(`${apiAddress}/persons/personGroups/${groupLabel}`, {
    method: "DELETE",
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Error deleting person group");
  }
}
