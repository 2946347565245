import Vue from "vue";
import Vuex from "vuex";
import "../assets/css/cssreset.css";
import * as apiService from "@/helpers/apiService";
import * as personService from "@/helpers/personService";
import * as wearableService from "@/helpers/wearableService";
import { stopMustering, startMustering } from "../helpers/musteringService";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import personModule from "@/store/personModule.ts";
import systemStatusModule from "@/store/systemStatusModule.ts";
import issueModule from "@/store/issueModule.ts";
import alarmSoundModule from "@/store/alarmSoundModule.ts";
import { isAlertOnActiveHardware } from "@/helpers/hardwareHelpers";
import { isNodePlaced, isNodeAMusterstation } from "scanreach-frontend-components/src/utils/hardwareHelpers";
import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";

/** @typedef {import('../typedef').SiteConfig} SiteConfig */
/** @typedef {import('../typedef').DashboardWearable} DashboardWearable */
/** @typedef {import('../typedef').DashboardNode} DashboardNode */
/** @typedef {import('../typedef').ApiNode} ApiNode */
/** @typedef {import('../typedef').ApiWearable} ApiWearable */
/** @typedef {import('../typedef').ApiEvent} ApiEvent */
/** @typedef {import('../typedef').SensorData} SensorData */
/** @typedef {import('../typedef').ApiWriteEvent} ApiWriteEvent */
/** @typedef {import('../typedef').ChangeMessage} ChangeMessage */
/** @typedef {import('../typedef').EventChangeMessage} EventChangeMessage */
/** @typedef {import('../typedef').TagChangeMessage} TagChangeMessage */
/** @typedef {import('../typedef').NodeChangeMessage} NodeChangeMessage */
/** @typedef {import('../typedef').ConfigurationChangeMessage} ConfigurationChangeMessage */
/** @typedef {import('../typedef').NodePeopleCount} NodePeopleCount */
/** @typedef {import('../typedef').NodeDetailsData} NodeDetailsData */
/** @typedef {import('scanreach-frontend-components/src/types/Issue.type').Issue} Issue */
/** @typedef {import('../types/personTypes').DashboardPerson} DashboardPerson */
/** @typedef {import('../types/personTypes').PersonChangeMessage} PersonChangeMessage */

Vue.use(Vuex);

/**
 * @typedef {Object} State
 * @prop {(SiteConfig | null)} siteConfig
 * @prop {boolean} globalError
 * @prop {string} globalErrorMessage
 * @prop {boolean} showGlobalErrorReloadButton
 * @prop {{[eventId: string]: ApiEvent}} events
 * @prop {(Issue | null)} musteringEvent
 * @prop {Map<string,number>} macToNodeIndex
 * @prop {Map<string,number>} macToWearableIndex
 * @prop {string | null} messageFromStore
 * @prop {"Dayview" | "Nightview"} theme
 * @prop {boolean} siteConfigLoading
 * @prop {boolean} siteConfigLoaded
 */

/**@type {State} */
const initialState = {
  siteConfig: null,
  globalError: false,
  globalErrorMessage: "",
  showGlobalErrorReloadButton: false,
  events: {}, // Stores all incoming events
  musteringEvent: null,
  macToNodeIndex: new Map(), // Used for faster mac lookup
  macToWearableIndex: new Map(), // Used for faster mac lookup
  messageFromStore: null, // Used to trigger a toaster in desktopApp. Example: configurationChange event would like to notify user of new data
  theme: "Nightview",
  siteConfigLoading: false,
  siteConfigLoaded: false,
};

/**
 * Used to avoid having more than one interval responsible for fetching sensorData
 * @type {number|null}
 */
let subscribedToSensorDataInterval = null;
const toaster = useToaster();
const store = new Vuex.Store({
  modules: {
    person: personModule,
    systemStatus: systemStatusModule,
    issueModule: issueModule,
    alarmSoundModule: alarmSoundModule,
  },
  /**@type {State} */
  state: initialState,

  mutations: {
    SET_SITECONFIGLOADING(state, status) {
      state.siteConfigLoading = status;
    },
    SET_SITECONFIGLOADED(state, status) {
      state.siteConfigLoaded = status;
    },
    /**
     * @param {State} state
     * @param {SiteConfig} data
     */
    SET_SITE_CONFIG(state, data) {
      state.siteConfig = data;
    },
    /** Clear the state, performed when logging out
     * @param {State} state
     */
    SET_STATE_TO_INITIAL_STATE(state) {
      state.siteConfig = null;
      state.events = [];
      state.musteringEvent = null;
      state.macToNodeIndex = new Map();
      state.macToWearableIndex = new Map();
    },
    /**
     *
     * @param {State} state
     * @param {ApiNode | DashboardNode} node
     */
    UPDATE_SITE_CONFIG_NODE(state, node) {
      const originalIndex = state.siteConfig?.nodes.findIndex((f) => f.id === node.id);
      /**@type {DashboardNode} */
      const original = state.siteConfig?.nodes[originalIndex];

      // Check if node is a musterstaion or not
      // Need this if statement since update from changeEvent uses musterstation prop
      // While info internally uses isMusterStation
      // A bit messy
      let isMusterstation = false;
      if (!Object.prototype.hasOwnProperty.call(node, "isMusterStation")) {
        // Incomiong node is coming from changeEvent
        // => Define based on musterStation field
        isMusterstation = node.musterStation !== null;
      } else {
        // Incoming node is coming from internal invocation
        // => Rely on isMusterStation
        isMusterstation = Object.prototype.hasOwnProperty.call(node, "isMusterStation")
          ? node.isMusterStation
          : original.isMusterStation;
      }
      // Some properties are added afterwards, and not included in the updateObject
      // Therefor need to check and add those properties
      Vue.set(state.siteConfig?.nodes, originalIndex, {
        ...node,
        sensorData: original.sensorData,
        isMusterStation: isMusterstation,
        unlocated: (node.x == null && node.y == null) || (node.x == 0 && node.y == 0) ? true : false,
        unresolvedIssueIds: Object.prototype.hasOwnProperty.call(node, "unresolvedIssueIds")
          ? node.unresolvedIssueIds
          : original.unresolvedIssueIds,
      });
      state.siteConfig?.nodes[originalIndex].unresolvedIssueIds.forEach((issueId) => {
        const issue = state.issueModule.issues[issueId];
        if (issue) {
          Vue.set(issue, "isConnectedNodeOrPersonActive", isNodePlaced(node));
        }
      });
    },
    /**
     *
     * @param {State} state
     * @param {ApiWearable} wearable
     */
    UPDATE_SITE_CONFIG_WEARABLE(state, wearable) {
      const originalIndex = state.siteConfig?.wearables.findIndex((f) => f.id === wearable.id);
      /**@type {DashboardWearable} */
      const original = state.siteConfig?.wearables[originalIndex];

      Vue.set(state.siteConfig?.wearables, originalIndex, {
        ...wearable,
        sensorData: original.sensorData,
        unresolvedIssueIds: Object.prototype.hasOwnProperty.call(wearable, "unresolvedIssueIds")
          ? wearable.unresolvedIssueIds
          : original.unresolvedIssueIds,
      });
    },

    /**
     * Set global error, visible on top of everything
     * @param {State} state
     * @param {{value: boolean, message: string, showReload: boolean}} param1
     */
    TOGGLE_GLOBAL_ERROR(state, { value, message, showReload }) {
      state.globalError = value;
      state.globalErrorMessage = message;
      state.showGlobalErrorReloadButton = showReload;
    },

    /**
     * Batch update sensorData based on data coming from wearableService.fetchWearableSensorData
     * @param {State} state
     * @param {{data: {[mac: string]: SensorData}}} payload
     */
    SET_WEARABLE_SENSORDATA(state, { wearable, sensorData }) {
      if (!wearable.sensorData) {
        Vue.set(wearable, "sensorData", sensorData);
      } else {
        wearable.sensorData = sensorData;
      }
    },

    /**
     * Batch update sensorData based on data coming from apiService.fetchNodeSensorData
     * @param {State} state
     * @param {{node: DashboardNode, sensorData: SensorData}} payload
     */
    SET_NODE_SENSORDATA(state, { node, sensorData }) {
      if (!node.sensorData) {
        Vue.set(node, "sensorData", sensorData);
      } else {
        if (!Object.hasOwnProperty.call(sensorData, "wearableMacsAtNode")) {
          sensorData.wearableMacsAtNode = node.sensorData?.wearableMacsAtNode;
        }
        node.sensorData = sensorData;
      }
    },

    /**
     * @param {State} state
     * @param {(Issue | null)} musteringEvent
     */
    SET_MUSTERING_ISSUE(state, musteringEvent) {
      state.musteringEvent = musteringEvent;
    },

    /**
     * Used on startup, after fetching events from api
     * Then this mutation stores those events in state
     * @param {State} state
     * @param {ApiEvent[]} events
     */
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_MAC_TO_NODE_INDEX(state, macToNodeIndex) {
      state.macToNodeIndex = macToNodeIndex;
    },
    SET_MAC_TO_WEARABLE_INDEX(state, macToWearableIndex) {
      state.macToWearableIndex = macToWearableIndex;
    },

    /**
     * Update a hardware's unresolved issues
     * @param {State} state
     * @param {{[issueId: string]: Issue}} issuesById,
     */
    ADD_HARDWARE_UNRESOLVED_ISSUE(state, issuesById) {
      for (const issueId in issuesById) {
        const issue = issuesById[issueId];
        const oldNodeMac = state.issueModule.issues[issueId]?.node?.mac;
        if (issue.wearable) {
          // Update wearable
          /**@type {DashboardWearable} */
          const wearable = state.siteConfig?.wearables?.find((w) => w.id === issue.wearable?.id);
          if (wearable) {
            if (!wearable.unresolvedIssueIds) {
              wearable.unresolvedIssueIds = [];
            }
            const existingEventIdx = wearable.unresolvedIssueIds.findIndex((id) => id === issue.id);
            if (issue.resolvedUtc) {
              // Event is resolved -> Remove it from unresolvedEvents
              if (existingEventIdx >= 0) {
                wearable.unresolvedIssueIds.splice(existingEventIdx, 1);
              }
            } else {
              // Add issue
              if (existingEventIdx < 0) {
                wearable.unresolvedIssueIds.push(issue.id);
              }
              // Add issue to correct node
              if (wearable.sensorData?.lastObservedNodeId) {
                issue.node = state.siteConfig?.nodes?.find(
                  (n) => n.mac == wearable.sensorData?.lastObservedNodeId,
                );
              }
            }
          }
        }

        if (issue.node) {
          // Update node
          /**@type {DashboardNode} */
          const node = state.siteConfig?.nodes.find((n) => n.id === issue.node?.id);
          if (node) {
            if (!node.unresolvedIssueIds) {
              node.unresolvedIssueIds = [];
            }
            const existingEventIdx = node.unresolvedIssueIds.findIndex((id) => id === issue.id);
            if (issue.resolvedUtc) {
              // Event is resolved -> Remove it from unresolvedEvents
              if (existingEventIdx >= 0) {
                node.unresolvedIssueIds.splice(existingEventIdx, 1);
              }
            } else {
              // Add issue
              if (existingEventIdx < 0) {
                node.unresolvedIssueIds.push(issue.id);
              }
            }
          }
        }

        if (oldNodeMac && oldNodeMac != issue.node?.mac) {
          // Remove issue from oldNode
          /**@type {DashboardNode} */
          const oldNode = state.siteConfig?.nodes?.find((n) => n.mac === oldNodeMac);
          if (oldNode) {
            const existingEventIdx = oldNode.unresolvedIssueIds?.findIndex((id) => id === issue.id);
            if (existingEventIdx >= 0) {
              oldNode.unresolvedIssueIds.splice(existingEventIdx, 1);
            }
          }
        }

        if (issue.personId) {
          // Update person
          /**@type {DashboardPerson} */
          const person = state.person.people[issue.personId];
          if (person) {
            if (!person.unresolvedIssueIds) {
              person.unresolvedIssueIds = [];
            }
            const existingIssueIdx = person.unresolvedIssueIds.findIndex((id) => id === issue.id);
            if (issue.resolvedUtc) {
              // Issue is resolved -> Remove it from unresolvedIssueIdx
              if (existingIssueIdx >= 0) {
                person.unresolvedIssueIds.splice(existingIssueIdx, 1);
              }
            } else {
              // Add issue
              if (existingIssueIdx < 0) {
                person.unresolvedIssueIds.push(issue.id);
              }
            }
          }
        }
      }
    },
    /**
     * Add PeopleCount to Nodes. Used for displaying number of people at node
     * @param {State} state
     * @param {{[nodeMac: string]: NodePeopleCount}} nodesPeopleCount
     */
    SET_PEOPLECOUNT_TO_NODES(state, nodesPeopleCount) {
      function updateNodePeopleCount(nodeMac, /**@type {NodePeopleCount} */ peopleCount) {
        /**@type {DashboardNode} */
        let node;
        const nodeIdx = state.macToNodeIndex.get(nodeMac);
        if (nodeIdx != undefined) {
          node = state.siteConfig?.nodes[nodeIdx];
        } else {
          node = state.siteConfig?.nodes.find((n) => n.mac === nodeMac);
        }
        if (node) {
          if (!node.peopleCount) {
            Vue.set(node, "peopleCount", peopleCount);
          } else {
            // These operations stops the mapMarkers from rerendering each time we get a new snapshot
            // Previously when replacing the whole object I had CPU spikes to 10% every n seconds
            // Now I have CPU spikes to 3% every n seconds
            // We do not care about the other roles at the moment.
            node.peopleCount.peopleCount = peopleCount.peopleCount;
            node.peopleCount["rescuer"] = peopleCount["rescuer"];
          }
        }
      }

      const notUpdatedNodes = new Map(state.macToNodeIndex);

      // Update nodes from snapshot.
      for (const nodeMac of Object.keys(nodesPeopleCount)) {
        updateNodePeopleCount(nodeMac, nodesPeopleCount[nodeMac]);
        notUpdatedNodes.delete(nodeMac);
      }

      // Clear nodes not contained in snapshot.
      for (const nodeMac of notUpdatedNodes.keys()) {
        updateNodePeopleCount(nodeMac, { peopleCount: 0, rescuer: 0 });
      }
    },
    /**
     * Mutate state.messageFromStore
     * @param {State} state
     * @param {string | null} msg
     */
    SET_MESSAGE_FROM_STORE(state, msg) {
      state.messageFromStore = msg;
    },

    /**
     * Set current theme of application.
     * @param {State} state
     * @param {"Dayview" | "Nightview"} theme
     */
    SET_APP_THEME(state, theme) {
      if (theme == "Dayview" || theme == "Nightview") {
        state.theme = theme;
      } else {
        console.error(`${theme} is not a valid theme color.`);
      }
    },
  },

  getters: {
    /**
     * @param {State} state
     * @returns {DashboardWearable[]}
     */
    wearables(state) {
      return state.siteConfig?.wearables ?? [];
    },
    /**
     * Return wearable by mac
     * @param {State} state
     * @param {string} mac
     * @returns {(DashboardWearable | null)}
     */
    getWearableByMac: (state) => (mac) => {
      if (!mac) {
        return null;
      }

      const hardwareIdx = state.macToWearableIndex.get(mac);
      if (hardwareIdx != undefined) {
        // Hardware has mapped index
        const wearable = state.siteConfig?.wearables[hardwareIdx];
        if (wearable.mac == mac) {
          return wearable;
        }
      }
      // Mac was not found, try finding it manually
      // Only scenario I can think of is if there was added hardware in cloud and then synced down
      // But that should trigger an configurationChangeEvent which should reload the whole webpage
      if (state.siteConfig?.wearables) {
        for (let i = 0; i < state.siteConfig?.wearables.length; i++) {
          /**@type {DashboardWearable} */
          const element = state.siteConfig?.wearables[i];
          state.macToWearableIndex.set(mac, i);
          if (element.mac === mac) {
            return element;
          }
        }
      } else {
        return null;
      }
    },
    /**
     * @param {State} state
     * @returns {DashboardNode[]}
     */
    nodes(state) {
      return state.siteConfig?.nodes ?? [];
    },
    /**
     * Return node by macOrId
     * @param {State} state
     * @param {string} mac
     * @returns {(DashboardNode | null)}
     */
    getNodeByMac: (state) => (mac) => {
      if (!mac) {
        return null;
      }

      const hardwareIdx = state.macToNodeIndex.get(mac);
      if (hardwareIdx !== undefined) {
        // Hardware has mapped index
        const node = state.siteConfig?.nodes[hardwareIdx];
        if (node.mac === mac) {
          return node;
        }
      }
      // Mac was not found, try finding it manually
      // Only scenario I can think of is if there was added hardware in cloud and then synced down
      // But that should trigger an configurationChangeEvent which should reload the whole webpage
      if (state.siteConfig?.nodes) {
        for (let i = 0; i < state.siteConfig?.nodes.length; i++) {
          /**@type {DashboardNode} */
          const element = state.siteConfig?.nodes[i];
          state.macToNodeIndex.set(element.mac, i);
          if (element.mac === mac) {
            return element;
          }
        }
      }
      return null;
    },

    /**
     * Get current theme of application.
     */
    appTheme: (state) => {
      return state.theme;
    },

    // TODO: Remove get events
    events: (state) => {
      return Object.values(state.events);
    },

    // TODO: Remove getUnresolvedEventsOnX
    /**
     * @Deprecated
     * Get all unresolvedEvents on node
     * @param {State} state
     * @param {any} getters
     * @param {string} nodeMac
     * @returns {ApiEvent[]}
     */
    getUnresolvedEventsOnNode: (state, getters) => (nodeMac) => {
      /**@type {DashboardNode} */
      const unresolvedEvents = [];
      const node = getters.getNodeByMac(nodeMac);
      if (node && node.unresolvedIssueIds) {
        node.unresolvedIssueIds.forEach((eventId) => {
          const event = state.events[eventId];
          if (event) {
            unresolvedEvents.push(event);
          }
        });
      }
      return unresolvedEvents;
    },

    /**
     * @Deprecated
     * Get all unresolvedEvents on wearable
     * @param {State} state
     * @param {any} getters
     * @param {string} wearableMac
     * @returns {ApiEvent[]}
     */
    getUnresolvedEventsOnWearable: (state, getters) => (wearableMac) => {
      /**@type {DashboardWearable} */
      const unresolvedEvents = [];
      const wearable = getters.getWearableByMac(wearableMac);
      if (wearable && wearable.unresolvedIssueIds) {
        wearable.unresolvedIssueIds.forEach((eventId) => {
          const event = state.events[eventId];
          if (event) {
            unresolvedEvents.push(event);
          }
        });
      }
      return unresolvedEvents;
    },
    // =========================
  },

  actions: {
    /**
     *
     * @param {{commit: any, state: State}} param0
     */
    async getSiteConfig({ commit, state, dispatch }) {
      try {
        commit("SET_SITECONFIGLOADING", true);
        /**@type {SiteConfig} */
        const siteConfig = {};
        const macToNodeIndex = new Map();
        const macToWearableIndex = new Map();
        const wearableMacToPersonId = new Map();

        /**@type {DashboardPerson[]} */
        let peopleFromApiArr = [];
        /**@type {import("../types/personTypes").ApiPersonRole[]} */
        let personRoles = [];
        try {
          // Fetch all data from API in parallel
          const [wearables, nodes, baseLayers, view, peoplesFromApi, personRolesFromApi] = await Promise.all([
            wearableService.fetchWearables({ showWarning: false }),
            apiService.fetchNodes({ showWarning: false }),
            apiService.fetchBaseLayers({ showWarning: false }),
            apiService.fetchView({ showWarning: false }),
            personService.fetchPersons({ showWarning: false }),
            personService.fetchPersonRoles({ showWarning: false }),
          ]);
          siteConfig.wearables = wearables;
          siteConfig.nodes = nodes;
          siteConfig.baseLayers = baseLayers;
          siteConfig.view = view;
          peopleFromApiArr = peoplesFromApi;
          personRoles = personRolesFromApi;
        } catch (error) {
          if (error.message != "Unauthorized") {
            throw error;
          }
        }

        // Person related stuff
        /**@type {{ [id: string]: ApiPerson }} */
        const people = {};
        siteConfig.people = {};
        peopleFromApiArr.forEach((person) => {
          person.wearableMac = person.wearable?.mac;
          people[person.id] = person;
          if (person.wearable) {
            wearableMacToPersonId.set(person.wearable.mac, person.id);
          }
          person.unresolvedIssueIds = []; // Enables reactivity on places that show personIssues
        });

        commit("SET_PERSON_ROLES", personRoles);

        siteConfig.nodes?.forEach((item, idx) => {
          macToNodeIndex.set(item.mac, idx); // Update mac to index map
          Vue.set(item, "isMusterStation", isNodeAMusterstation(item));
          Vue.set(item, "unlocated", !isNodePlaced(item));
          Vue.set(item, "unresolvedIssueIds", []);
          delete item.unresolvedEvents;

          // Check if node already exists, if so fetch it's inital properties
          // then overwrite the fresh properties
          const existingNode = this.getters.getNodeByMac(item.mac);
          if (existingNode) {
            siteConfig.nodes[idx] = {
              ...existingNode,
              ...item,
            };
          }

          // Vue.set(item, "events", { alarms: [], warnings: [], notifications: [] });
        });

        siteConfig.wearables?.forEach((item, idx) => {
          macToWearableIndex.set(item.mac, idx); // Update mac to index map
          Vue.set(item, "unresolvedIssueIds", []);
          delete item.unresolvedEvents;
          // Check if wearable already exists, if so fetch it's inital properties
          // then overwrite the fresh properties
          const existingWearable = this.getters.getWearableByMac(item.mac);
          if (existingWearable) {
            siteConfig.wearables[idx] = {
              ...existingWearable,
              ...item,
            };
          }
        });

        if (state.globalError) {
          commit("TOGGLE_GLOBAL_ERROR", {
            value: false,
            message: "",
            showReload: false,
          });
        }

        commit("SET_SITE_CONFIG", siteConfig);
        commit("SET_MAC_TO_NODE_INDEX", macToNodeIndex);
        commit("SET_MAC_TO_WEARABLE_INDEX", macToWearableIndex);
        commit("SET_WEARABLE_MAC_TO_PERSON_ID", wearableMacToPersonId);
        commit("SET_PEOPLE", people);
        commit("SET_SITECONFIGLOADING", false);
        commit("SET_SITECONFIGLOADED", true);

        dispatch("fetchIssues");
        dispatch("fetchSilenceTimeFromAPI");
      } catch (err) {
        if (err.message != "403") {
          // User is not logged in, page will automatically redirect to login
          console.error(err);
          commit("TOGGLE_GLOBAL_ERROR", {
            value: true,
            message:
              "Failed to fetch this site's configuration. Try reloading the page. If the problem persists, contact support.",
            showReload: true,
          });
        }
        commit("SET_SITECONFIGLOADING", false);
      }
    },

    async updateSiteConfigNode({ commit }, { node, persistToBackend = true }) {
      if (node) {
        try {
          if (persistToBackend) {
            const not = ["unlocated", "sensorData"]; // properties to delete from parsedNode object
            const parsedNode = Object.assign({}, node);

            for (const n of not) delete parsedNode[n];

            const response = await apiService.saveNode(parsedNode);
            if (response.ok) {
              toaster.pushToast({
                title: "Node configuration was successfully sent",
                type: ToastType.SUCCESS,
                duration: 5000,
              });
              return response;
            } else {
              const parsedResponse = JSON.parse(await response.text());
              const message = parsedResponse.message;

              throw new Error(message);
            }
          }
          // commit("UPDATE_SITE_CONFIG_NODE", node); // SignalR service will handle updating the node in vuex Store
        } catch (err) {
          console.error("error saving node: ", err);
          toaster.pushToast({
            title: "Error while saving node configuration",
            body: err.message,
            type: ToastType.ERROR,
            duration: 10000,
          });
          node.notSynced = true;
          const nodeFromBackend = await apiService.fetchNode(node.id);
          commit("UPDATE_SITE_CONFIG_NODE", nodeFromBackend);
        }
      }
    },

    /**
     * @deprecated FIXME: This is not working anymore and should be fixed
     * Update a wearable in siteConfig
     * @param {*} param0
     * @param {{wearable: [DashboardWearable | ApiWearable]}} param1
     */
    async updateSiteConfigWearable({ commit }, { wearable }) {
      if (wearable) {
        try {
          await commit("UPDATE_SITE_CONFIG_WEARABLE", wearable);
          this.getters.getUnresolvedEventsOnWearable(wearable.mac).forEach((event) => {
            const person = this.getters.getPersonByWearableMac(event.tag?.mac);
            Vue.set(event, "isConnectedNodeOrPersonActive", isAlertOnActiveHardware(event, person));
            commit("UPDATE_EVENT", event);
          });
          return { ok: true };
        } catch (err) {
          console.error("error saving wearable: ", err);
          return { ok: false, error: err };
        }
      }
    },

    /**
     * Used to update sensorData on wearables. Data is same format as coming from wearableService.fetchWearableSensorData
     * @param {*} param0
     * @param {{data: {[mac: string]: SensorData}}} data
     */
    setWearablesSensorData({ commit, state }, data) {
      if (state.siteConfig?.wearables) {
        for (const wearableMac of Object.keys(data)) {
          /**@type {DashboardWearable} */
          const wearable = this.getters.getWearableByMac(wearableMac);
          if (wearable) {
            /**@type {SensorData} */
            const sensorData = data[wearableMac];
            let wearableMoved = false;
            if (wearable.sensorData?.lastObservedNodeId != sensorData.lastObservedNodeId) {
              sensorData.nodeName = this.getters.getNodeByMac(sensorData.lastObservedNodeId)?.label;
              wearableMoved = true;
            } else {
              sensorData.nodeName = wearable.sensorData?.nodeName;
            }
            commit("SET_WEARABLE_SENSORDATA", { wearable: wearable, sensorData: sensorData });
            if (wearableMoved) {
              const issuesToMove = {};
              wearable.unresolvedIssueIds.forEach((issueId) => {
                issuesToMove[issueId] = state.issueModule.issues[issueId];
              });
              commit("ADD_HARDWARE_UNRESOLVED_ISSUE", issuesToMove);
            }
          }
        }
      }
    },

    /**
     * Used to update sensorData on nodes. Data is same format as coming from apiService.fetchNodeSensorData
     * @param {*} param0
     * @param {{data: {[mac: string]: SensorData }}} data
     */
    setNodesSensorData({ commit, state }, data) {
      if (state.siteConfig && state.siteConfig?.nodes) {
        for (const nodeMac of Object.keys(data)) {
          /**@type {DashboardNode} */
          const node = this.getters.getNodeByMac(nodeMac);
          if (node) {
            /**@type {SensorData} */
            const sensorData = data[nodeMac];
            commit("SET_NODE_SENSORDATA", { node: node, sensorData: sensorData });
          }
        }
      }
    },

    /**
     * Add PeopleCount to Nodes. Used for displaying number of people at node
     * @param {{nodes: { [nodeMac: string]: PeopleCount }}} param0
     */
    setNodesPeopleCount({ commit }, nodePeopleCount) {
      commit("SET_PEOPLECOUNT_TO_NODES", nodePeopleCount);
    },

    /**
     *
     * @param {any} param0
     * @param {boolean} error
     * @param {[string]} message
     * @param {[boolean]} showReload
     */
    setOfflineStatus({ commit }, error, message, showReload) {
      commit("TOGGLE_GLOBAL_ERROR", {
        value: error,
        message: message ?? "Network seems to be offline. Trying to reconnect...",
        showReload: showReload ?? false,
      });
    },

    /**
     * First get all events from api
     * Then start listening for new apiEvents coming from backend
     */

    subscribeToEvents({ commit, state, dispatch }) {
      signalRSocketHandler.connect();
      signalRSocketHandler.subscribe("statuschange", (status) => {
        dispatch("setOfflineStatus", status.error || !status.connected);
        if (!state.siteConfigLoaded && !state.siteConfigLoading) {
          dispatch("getSiteConfig");
        }
      });
      signalRSocketHandler.subscribe("reconnect", () => {
        console.info("SignalR reconnected, reloading data from API.");
        dispatch("setOfflineStatus", false);
        dispatch("getSiteConfig");
      });
      signalRSocketHandler.on("ReceiveConfigurationChangeEvent", () => {
        this.dispatch("getSiteConfig");
        commit(
          "SET_MESSAGE_FROM_STORE",
          "There has been a configuration change from cloud. Data is updated automatically.",
        );
      });
      signalRSocketHandler.on("ReceiveTagConfigurationChangeEvent", (/**@type {TagChangeMessage} */ msg) => {
        this.dispatch("updateSiteConfigWearable", { wearable: msg, persistToBackend: false });
      });
      signalRSocketHandler.on(
        "ReceiveNodeConfigurationChangeEvent",
        (/**@type {NodeChangeMessage} */ msg) => {
          commit("UPDATE_SITE_CONFIG_NODE", msg);
        },
      );
      signalRSocketHandler.on(
        "ReceivePersonConfigurationChangeEvent",
        (/**@type {PersonChangeMessage} */ msg) => {
          if (msg.deletedUtcDateTime) {
            // delete user
            this.dispatch("deletePerson", { personId: msg.id, persistToBackend: false });
          } else {
            this.dispatch("updatePerson", { person: msg, persistToBackend: false });
          }
        },
      );
    },

    /**
     * Fetches sensorData for all wearables and nodes at periodic interval
     */
    subscribeToSensorData({ dispatch }) {
      if (!subscribedToSensorDataInterval) {
        subscribedToSensorDataInterval = setInterval(async () => {
          try {
            const nodeSensorData = await apiService.fetchNodeSensorData();
            dispatch("setNodesSensorData", nodeSensorData);
          } catch (error) {
            console.error(error);
          }
          try {
            const wearableSensorData = await wearableService.fetchWearableSensorData();
            dispatch("setWearablesSensorData", wearableSensorData);
          } catch (error) {
            console.error(error);
          }
        }, 3000);
      }
    },

    toggleMustering({ commit, state }, params) {
      if (state.musteringEvent) {
        // Turn off mustering
        stopMustering(params.comment)
          .then(() => {
            commit("SET_MUSTERING_ISSUE", null);
          })
          .catch((error) => {
            // TODO: Better error handling here
            console.error(error);
          });
      } else {
        // Turn on mustering
        startMustering(params.comment, params.isTraining)
          .then(() => {
            // Mustering started
          })
          .catch((error) => {
            // TODO: Better error handling here
            console.error(error);
          });
      }
    },
  },
});

export default store;
export const useStore = () => store;
